<template>
  <div>
    <div v-if="item">
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title
          >{{ $t("OrderView.page_title") }}
          {{ item.id ? " ID: " + item.id.slice(0, 8) : "" }}</v-toolbar-title
        >
        <!-- refresh button -->
        <v-btn
          icon
          @click="refreshItem()"
          v-show="displayOnlyForAdmin"
          class="ml-4"
          :title="$t('btnUpdate')"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-checkbox
          v-model="reducePositions"
          v-show="displayOnlyForAdmin"
          :title="$t('OrderView.reducePositions_title')"
          hide-details
          class="ml-4"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-icon x-large>mdi-road-variant</v-icon>
        <template v-slot:extension>
          <v-tabs
            v-model="tabs"
            :align-with-title="$vuetify.breakpoint.mdAndUp"
            center-active
            show-arrows
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="tab1"
              ><v-icon :left="$vuetify.breakpoint.mdAndUp">
                mdi-information-outline</v-icon
              ><span v-show="$vuetify.breakpoint.mdAndUp">{{
                $t("OrderView.tabInfo")
              }}</span></v-tab
            >
            <v-tab key="tab2" @click="fitBoundsMap()"
              ><v-icon :left="$vuetify.breakpoint.mdAndUp"> mdi-map</v-icon
              ><span v-show="$vuetify.breakpoint.mdAndUp">{{
                $t("OrderView.tabRoute")
              }}</span></v-tab
            >
            <v-tab key="tab3"
              ><v-icon :left="$vuetify.breakpoint.mdAndUp">
                mdi-timeline-clock-outline</v-icon
              ><span v-show="$vuetify.breakpoint.mdAndUp">{{
                $t("OrderView.tabTimeline")
              }}</span></v-tab
            >
            <v-tab key="tab4"
              ><v-icon :left="$vuetify.breakpoint.mdAndUp">
                mdi-cash-multiple</v-icon
              ><span v-show="$vuetify.breakpoint.mdAndUp">{{
                $t("OrderView.tabPrice")
              }}</span></v-tab
            >
          </v-tabs>
        </template>
      </v-toolbar>
      <!-- U tab2/trasa zablokovať touchless, lebo sa neda normalne pohybovat na mape  :touchless="tabs == 1" -->
      <v-tabs-items v-model="tabs" touchless>
        <!-- TAB1 -->
        <v-tab-item>
          <!-- <v-subheader class="font-weight-bold mt-4"
            >Základné údaje o objednávke</v-subheader
          >
          <v-divider class="mb-4"></v-divider>-->
          <v-container class="pa-1 my-0" fluid>
            <v-expansion-panels v-model="infoExpansionPanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-note-text-outline</v-icon
                    >{{ $t("OrderView.headerBasicData")
                    }}{{
                      !infoExpansionPanel.includes(0)
                        ? ": " +
                          item.id.slice(0, 8) +
                          " | " +
                          $t("orderStatusSimple." + item.status) +
                          " | " +
                          $t("orderType." + item.type)
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.id"
                        :label="$t('OrderView.id')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        v-model="item.status"
                        :title="$t('orderStatus_tooltip.' + item.status)"
                        :items="$t('orderStatusArray')"
                        :label="$t('OrderView.status')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        v-model="item.source"
                        :title="$t('source_tooltip.' + item.source)"
                        :items="$t('sourceArray')"
                        :label="$t('OrderView.source')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        v-model="item.type"
                        :title="$t('orderType_tooltip.' + item.type)"
                        :items="$t('orderTypeArray')"
                        :label="$t('OrderView.type')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        v-model="item.paymentType"
                        :title="$t('paymentType_tooltip.' + item.paymentType)"
                        :items="$t('paymentTypeArray')"
                        :label="$t('OrderView.paymentType')"
                        readonly
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="3" v-if="item.preferredDriver">
                      <v-text-field
                        v-model="item.preferredDriver.firstName"
                        :label="$t('OrderView.preferredDriverFirstName')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" v-if="item.preferredDriver">
                      <v-text-field
                        v-model="item.preferredDriver.lastName"
                        :label="$t('OrderView.preferredDriverLastName')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.requestedTime"
                        :label="$t('OrderView.requestedTime')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.presendTime"
                        :label="$t('OrderView.presendTime')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.clientRating"
                        :label="$t('OrderView.orderClientRating')"
                        readonly
                      ></v-text-field> </v-col
                    ><v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driverRating"
                        :label="$t('OrderView.orderDriverRating')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-show="displayOnlyForAdmin">
                    <v-col cols="12" sm="6" md="3" v-if="item.preferredDriver">
                      <v-text-field
                        v-model="item.preferredDriver.id"
                        :label="$t('OrderView.preferredDriverId')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.note"
                        :label="$t('OrderView.note')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- <v-subheader v-if="item.client" class="font-weight-bold mt-4"
              >Klient</v-subheader
            >
            <v-divider v-if="item.client" class="mb-4"></v-divider>-->

              <v-expansion-panel>
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-human-greeting</v-icon
                    >{{ $t("OrderView.headerClient")
                    }}{{
                      !infoExpansionPanel.includes(1) && item.client
                        ? ": " +
                          item.client.nickname +
                          " - " +
                          $t("clientMark." + item.client.mark) +
                          (item.client.note ? " | " + item.client.note : "")
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense v-if="item.client">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.client.nickname"
                        :label="$t('OrderView.nickname')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        v-model="item.client.mark"
                        :items="$t('clientMarkSelect')"
                        :label="$t('OrderView.mark')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.client.phone"
                        :label="$t('OrderView.clientPhone')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.client.note"
                        :label="$t('OrderView.clientNote')"
                        readonly
                      ></v-text-field>
                    </v-col> </v-row
                  ><v-row dense v-if="item.client">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.client.rating"
                        :label="$t('OrderView.clientRating')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.client.driveCounter"
                        :label="$t('OrderView.clientDriveCounter')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.client.rateCount"
                        :label="$t('OrderView.clientRateCount')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.client.rateSum"
                        :label="$t('OrderView.clientRateSum')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--<v-subheader v-if="item.account" class="font-weight-bold mt-4"
            >Firemná jazda</v-subheader
          >
          <v-divider v-if="item.account" class="mb-4"></v-divider>-->
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-briefcase-variant</v-icon
                    >{{ $t("OrderView.headerAccount")
                    }}{{
                      !infoExpansionPanel.includes(2) && item.account
                        ? item.account.id && item.account.name
                          ? ": " + item.account.name
                          : ""
                        : " - "
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense v-if="item.account">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.account.name"
                        :label="$t('OrderView.accountName')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.account.status"
                        :label="$t('OrderView.accountStatus')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" v-show="displayOnlyForAdmin">
                      <v-text-field
                        v-model="item.account.id"
                        :label="$t('OrderView.accountId')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!--  else empty info message -->
                  <v-row dense v-else>
                    <v-col cols="12" sm="6" md="3">
                      <p>{{ $t("OrderView.accountNone") }}</p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <v-subheader v-if="item.driver" class="font-weight-bold mt-4"
            >Vodič</v-subheader
          >
          <v-divider v-if="item.driver" class="mb-4"></v-divider> -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-account-tie-outline</v-icon
                    >{{ $t("OrderView.headerDriver")
                    }}{{
                      !infoExpansionPanel.includes(3) && item.driver
                        ? (item.driver.firstName
                            ? ": " + item.driver.firstName
                            : "") +
                          (item.driver.lastName
                            ? " " + item.driver.lastName
                            : "") +
                          (item.driver.nickname
                            ? " - " + item.driver.nickname
                            : "")
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense v-if="item.driver">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.firstName"
                        :label="$t('OrderView.driverFirstName')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.lastName"
                        :label="$t('OrderView.driverLastName')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.phone"
                        :label="$t('OrderView.driverPhone')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.nickname"
                        :label="$t('OrderView.driverNickname')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense v-if="item.driver">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.rating"
                        :label="$t('OrderView.driverRating')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.driveCounter"
                        :label="$t('OrderView.driverDriveCounter')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.rateCount"
                        :label="$t('OrderView.driverRateCount')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.rateSum"
                        :label="$t('OrderView.driverRateSum')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!--  else empty info message -->
                  <v-row dense v-else>
                    <v-col cols="12" sm="6" md="3">
                      <p>{{ $t("OrderView.driverNone") }}</p>
                    </v-col>
                  </v-row>

                  <v-row dense v-if="item.driver" v-show="displayOnlyForAdmin">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.driver.id"
                        :label="$t('OrderView.driverId')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <v-subheader v-if="item.vehicle" class="font-weight-bold mt-4"
            >Vozidlo</v-subheader
          >
          <v-divider v-if="item.vehicle" class="mb-4"></v-divider> -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-taxi</v-icon>Vozidlo{{
                      !infoExpansionPanel.includes(4) && item.vehicle
                        ? (item.vehicle.internalName
                            ? ": " + item.vehicle.internalName + " -"
                            : "") +
                          (item.vehicle.brand ? " " + item.vehicle.brand : "") +
                          (item.vehicle.model ? " " + item.vehicle.model : "") +
                          (item.vehicle.lpn ? " - " + item.vehicle.lpn : "")
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense v-if="item.vehicle">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.vehicle.internalName"
                        label="Interné označenie"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.vehicle.lpn"
                        label="ŠPZ"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.vehicle.brand"
                        label="Značka"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="item.vehicle.model"
                        label="Model"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense v-if="item.vehicle">
                    <v-col cols="12" sm="6" md="3">
                      <!-- <v-text-field
                        v-model="item.vehicle.type"
                        label="Typ"
                        readonly
                      ></v-text-field> -->
                      <v-select
                        v-model="item.vehicle.type"
                        :label="$t('Vehicle.type')"
                        :items="$t('VehicleEnums.TypeArray')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <!-- <v-text-field
                        v-model="item.vehicle.color"
                        label="Farba"
                        readonly
                      ></v-text-field> -->
                      <v-select
                        v-model="item.vehicle.color"
                        :label="$t('Vehicle.color')"
                        :items="$t('vehicleColorArray')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <!-- <v-text-field
                        v-model="item.vehicle.status"
                        label="Stav"
                        readonly
                      ></v-text-field> -->
                      <v-select
                        v-model="item.vehicle.status"
                        :label="$t('Vehicle.status')"
                        :items="$t('VehicleEnums.StatusArray')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" v-show="displayOnlyForAdmin">
                      <v-text-field
                        v-model="item.vehicle.id"
                        label="ID"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!--  else empty info message -->
                  <v-row dense v-else>
                    <v-col cols="12" sm="6" md="3">
                      <p>No data</p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- filter: airconditioning, animal, autonomous, childrenChair, luggage, eco, maxPerson, smoking, specialTransport -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-cog-box</v-icon
                    >{{ $t("OrderView.headerOrderFilter")
                    }}{{
                      !infoExpansionPanel.includes(5) &&
                      item.filter /*(item.filter.airconditioning != "ANY"
                          ? " | " + $t("orderFilter.airconditioning")
                          : "") +*/
                        ? (item.filter.animal != "ANY"
                            ? " | " + $t("orderFilter.animal")
                            : "") +
                          /*(item.filter.autonomous != "ANY"
                          ? " | " + $t("orderFilter.autonomous")
                          : "") +*/
                          (item.filter.childrenChair != "ANY"
                            ? " | " + $t("orderFilter.childrenChair")
                            : "") +
                          (item.filter.luggage != "ANY"
                            ? " | " + $t("orderFilter.luggage")
                            : "") +
                          /*(item.filter.eco != "ANY"
                          ? " | " + $t("orderFilter.eco")
                          : "") +*/
                          (item.filter.maxPerson != 0
                            ? " | " + $t("orderFilter.maxPerson")
                            : "") +
                          /*(item.filter.smoking != "ANY"
                          ? " | " + $t("orderFilter.smoking")
                          : "") +*/
                          (item.filter.specialTransport != "ANY"
                            ? " | " + $t("orderFilter.specialTransport")
                            : "") +
                          (item.filter.paymentTerminal != "ANY"
                            ? " | " + $t("orderFilter.paymentTerminal")
                            : "")
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense v-if="item.filter">
                    <!-- <v-col cols="12" sm="6" md="3">
                    <v-select
                      :label="$t('orderFilter.airconditioning')"
                      v-model="item.filter.airconditioning"
                      :items="$t('orderFilterSelect.airconditioning')"
                      readonly
                    ></v-select>
                  </v-col> -->
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        :label="$t('orderFilter.animal')"
                        v-model="item.filter.animal"
                        :items="$t('orderFilterSelect.animal')"
                        readonly
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="3">
                    <v-select
                      :label="$t('orderFilter.autonomous')"
                      v-model="item.filter.autonomous"
                      :items="$t('orderFilterSelect.autonomous')"
                      readonly
                    ></v-select>
                  </v-col> -->
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        :label="$t('orderFilter.paymentTerminal')"
                        v-model="item.filter.paymentTerminal"
                        :items="$t('orderFilterSelect.paymentTerminal')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        :label="$t('orderFilter.childrenChair')"
                        v-model="item.filter.childrenChair"
                        :items="$t('orderFilterSelect.childrenChair')"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        :label="$t('orderFilter.luggage')"
                        v-model="item.filter.luggage"
                        :items="$t('orderFilterSelect.luggage')"
                        readonly
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="3">
                    <v-select
                      :label="$t('orderFilter.eco')"
                      v-model="item.filter.eco"
                      :items="$t('orderFilterSelect.eco')"
                      readonly
                    ></v-select>
                  </v-col> -->
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        :label="$t('orderFilter.maxPerson')"
                        v-model="item.filter.maxPerson"
                        :items="$t('orderFilterSelect.maxPerson')"
                        readonly
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="3">
                    <v-select
                      :label="$t('orderFilter.smoking')"
                      v-model="item.filter.smoking"
                      :items="$t('orderFilterSelect.smoking')"
                      readonly
                    ></v-select>
                  </v-col> -->
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        :label="$t('orderFilter.specialTransport')"
                        v-model="item.filter.specialTransport"
                        :items="$t('orderFilterSelect.specialTransport')"
                        readonly
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <v-icon
                      title="Zobrazenie a
                doručenie"
                      >mdi-cellphone-cog</v-icon
                    >
                    {{ $t("OrderView.headerDisplayAndSend")
                    }}{{
                      !infoExpansionPanel.includes(6)
                        ? (item.showPhone == true
                            ? " | " + $t("OrderView.showPhone_short") + " "
                            : " ") +
                          (item.showAllAddresses == true
                            ? " | " +
                              $t("OrderView.showAllAddresses_short") +
                              " "
                            : " ") +
                          (item.onlyManualSending == true
                            ? "|  " +
                              $t("OrderView.onlyManualSending_short") +
                              " "
                            : " ") +
                          (item.forcedPickupRadius > 0
                            ? " | " +
                              $t("OrderView.forcedPickupRadius_short") +
                              " " +
                              item.forcedPickupRadius
                            : " ")
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        :label="$t('OrderView.showPrice')"
                        dense
                        readonly
                        prepend-icon="mdi-cash-multiple"
                        v-model="item.showPrice"
                        :items="$t('showPriceArray')"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        :label="$t('OrderView.showDriverPhoneButton')"
                        dense
                        readonly
                        prepend-icon="mdi-phone-dial-outline"
                        v-model="item.showDriverPhoneButton"
                        :items="$t('showDriverPhoneButtonArray')"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-checkbox
                        :label="$t('OrderView.showPhone')"
                        dense
                        readonly
                        append-icon=""
                        v-model="item.showPhone"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-checkbox
                        :label="$t('OrderView.showAllAddresses')"
                        dense
                        readonly
                        append-icon=""
                        v-model="item.showAllAddresses"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-checkbox
                        :label="$t('OrderView.onlyManualSending')"
                        dense
                        readonly
                        append-icon=""
                        v-model="item.onlyManualSending"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        :label="$t('OrderView.forcedPickupRadius')"
                        dense
                        readonly
                        prepend-icon="mdi-map-marker-radius-outline"
                        v-model="item.forcedPickupRadius"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- <v-subheader class="font-weight-bold mt-4">Trasa</v-subheader>
          <v-divider class="mb-4"></v-divider> -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-road-variant</v-icon
                    >{{ $t("OrderView.headerWaypointList")
                    }}{{
                      !infoExpansionPanel.includes(7) && item.waypointList
                        ? item.waypointList.length > 0
                          ? ` (${item.waypointList.length})`
                          : ""
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <template v-for="waypoint in orderDetailWaypointList">
                      <v-list-item :key="waypoint.id">
                        <v-list-item-avatar>
                          <v-icon>{{ addressIcons[waypoint.type] }}</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            :style="[
                              waypoint.status == 'DISABLED'
                                ? { 'text-decoration': 'line-through' }
                                : { 'text-decoration': 'none' },
                            ]"
                            >{{ waypoint.address }}</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            {{
                              waypoint.status +
                              " " +
                              waypoint.type +
                              " (" +
                              waypoint.ordering +
                              ") | " +
                              waypoint.latitude +
                              ", " +
                              waypoint.longitude +
                              ""
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <!--<v-icon :key="waypoint.id" class="mr-2">{{
                addressIcons[waypoint.type]
              }}</v-icon>
              <span
                :key="'W' + waypoint.id"
                class="d-inline-block"
                :style="[
                  waypoint.status == 'DISABLED'
                    ? { 'text-decoration': 'line-through' }
                    : { 'text-decoration': 'none' },
                ]"
              >
                {{ waypoint.status }} | {{ waypoint.address }}</span
              >
              <v-divider :key="index2" :inset="true"></v-divider>-->
                    </template>
                  </v-list>

                  <v-row
                    dense
                    v-if="item.waypointList"
                    v-show="displayOnlyForAdmin"
                  >
                    <!--OSM nevie na verejnej mape vizualizovat viac prejazdovych bodov, preto toto tlacislo blokujem-->
                    <!--<v-col cols="12" sm="6" md="3">
              <v-btn :href="urlOpenStreetMap" target="_blank">
                <v-icon>mdi-map-search-outline</v-icon>Simulácia OSM maps
              </v-btn>
            </v-col>-->
                    <v-col cols="12" sm="6" md="6">
                      <v-btn :href="urlGoogleMap" target="_blank">
                        <v-icon>mdi-google-maps</v-icon> Simulácia Google maps
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn :href="urlOsmRouting" target="_blank">
                        <v-icon>mdi-map-marker-distance</v-icon> Simulácia
                        vzdialeností
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-if="item.waypointList && defaultStand"
                    v-show="displayOnlyForAdmin"
                  >
                    <!--OSM nevie na verejnej mape vizualizovat viac prejazdovych bodov, preto toto tlacislo blokujem-->
                    <!--<v-col cols="12" sm="6" md="3">
              <v-btn :href="urlOpenStreetMapWithStand" target="_blank">
                <v-icon>mdi-map-search-outline</v-icon>Simulácia OSM maps
              </v-btn>
            </v-col>-->
                    <v-col cols="12" sm="6" md="6">
                      <v-btn :href="urlGoogleMapWithStand" target="_blank">
                        <v-icon>mdi-google-maps</v-icon> Simulácia Google maps
                        so stanoviskom
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn :href="urlOsmRoutingWithStand" target="_blank">
                        <v-icon>mdi-map-marker-distance</v-icon> Simulácia
                        vzdialeností so stanoviskom
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- Simulácia iba priamej trasy bez prejazdových bodov na základe reálne začiatku a konca jazdy-->
                  <v-row
                    dense
                    v-if="item.waypointList"
                    v-show="displayOnlyForAdmin"
                  >
                    <v-col cols="12" sm="6" md="6">
                      <v-btn
                        :href="urlGoogleMapReal"
                        target="_blank"
                        title="Nezohľadňuje prejazdové body!!! iba reálnu polohu začiatku a konca zajdy"
                      >
                        <v-icon>mdi-google-maps</v-icon> Simulácia Google maps
                        priamo z polohy vodiča
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn
                        :href="urlOsmRoutingReal"
                        target="_blank"
                        title="Nezohľadňuje prejazdové body!!! iba reálnu polohu začiatku a konca zajdy"
                      >
                        <v-icon>mdi-map-marker-distance</v-icon> Simulácia
                        vzdialeností priamo z polohy vodiča
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <v-subheader v-if="item.chatList" class="font-weight-bold mt-4"
            >Chat</v-subheader
          >
          <v-divider v-if="item.chatList" class="mb-4"></v-divider> -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-comment-text-multiple-outline</v-icon
                    >{{ $t("OrderView.headerChat")
                    }}{{
                      !infoExpansionPanel.includes(8) && item.chatList
                        ? item.chatList.length > 0
                          ? ` (${item.chatList.length})`
                          : ""
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <template v-for="chat in orderDetailChatList">
                      <v-list-item :key="chat.id">
                        <v-list-item-avatar>
                          <v-icon>mdi-comment-text-outline</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            chat.message
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >{{
                              chat.fromType +
                              " " +
                              (displayOnlyForAdmin == true
                                ? chat.fromId + " "
                                : "") +
                              chat.fromName
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            >{{ chat.createdAt | formatDateLong }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- item.orderSendLogs tabuľka so stlpcami driverId, fromBy, distance, createdAt -->
              <v-expansion-panel v-show="displayOnlyForAdmin">
                <v-expansion-panel-header
                  ><div>
                    <v-icon left>mdi-send</v-icon
                    >{{ $t("OrderView.headerOrderSentLogs")
                    }}{{
                      !infoExpansionPanel.includes(9) && item.orderSentLogs
                        ? item.orderSentLogs.length > 0
                          ? ` (${item.orderSentLogs.length})`
                          : ""
                        : ""
                    }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <template
                      v-for="(orderSentLog, index) in item.orderSentLogs"
                    >
                      <v-list-item :key="orderSentLog.orderId + index">
                        <v-list-item-avatar>
                          <v-icon>mdi-send-circle-outline</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            >{{
                              "Driver ID: " +
                              orderSentLog.driverId +
                              " | From: " +
                              orderSentLog.fromBy +
                              " | Distance: " +
                              orderSentLog.distance +
                              " | Created At: " +
                              orderSentLog.createdAt +
                              " | Status: " +
                              orderSentLog.sentStatus +
                              " | Message: " +
                              orderSentLog.message
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-tab-item>
        <v-tab-item eager>
          <!-- TAB2 -->
          <div id="map" ref="map"></div>
        </v-tab-item>
        <v-tab-item>
          <!-- TAB3 -->
          <!-- <v-subheader>Záznamy</v-subheader>-->
          <v-divider></v-divider>
          <v-timeline align-top :dense="$vuetify.breakpoint.mobile">
            <v-timeline-item
              v-for="item in timelineItems"
              :key="item.id"
              :color="item.type.color"
              :icon="item.type.icon"
              fill-dot
              large
            >
              <v-card :color="item.type.color" dark>
                <v-card-title class="pt-1 pb-3" style="word-break: normal">
                  {{ item.title }}
                </v-card-title>

                <v-card-text class="white text--primary pb-1" light>
                  <v-row>
                    <v-col>
                      <div class="grey--text text--darken-2 text-caption mb-2">
                        {{ item.formatedDate }}
                        {{ item.detail ? " | " + item.detail : "" }}
                      </div>
                      <div class="black--text text-caption mb-2">
                        {{ item.text }}
                      </div>
                      <!--<v-avatar>
                        <v-img
                          src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                        ></v-img>
                      </v-avatar> -->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-tab-item>
        <v-tab-item>
          <!-- TAB4 -->

          <!-- Sumarizacia finalnej ceny (Price Waiting, Price Journey, Price Extra, Discount, Price Total) -->
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="6" md="3" v-show="item.finalPriceWaiting">
              <v-card tile class="py-1 my-2 rounded-lg grey lighten-5">
                <v-card-text class="pa-0">
                  <v-row no-gutters>
                    <v-col class="py-1 ml-3">
                      <div class="display-1 grey--text text--darken-2">
                        {{
                          item.finalPriceWaiting
                            ? item.finalPriceWaiting + " " + item.currency
                            : "-"
                        }}
                      </div>
                      <span class="headline grey--text text--darken-1">{{
                        $t("OrderView.cardFinalPriceWaiting")
                      }}</span>
                    </v-col>

                    <div
                      class="d-flex justify-center align-center my-2 mx-4 pa-4 secondary rounded-lg"
                    >
                      <v-icon large color="white">mdi-clock-outline</v-icon>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-show="item.finalPriceJourney">
              <v-card tile class="py-1 my-2 rounded-lg grey lighten-5">
                <v-card-text class="pa-0">
                  <v-row no-gutters>
                    <v-col class="py-1 ml-3">
                      <div class="display-1 grey--text text--darken-2">
                        {{
                          item.finalPriceJourney
                            ? item.finalPriceJourney + " " + item.currency
                            : "-"
                        }}
                      </div>
                      <span class="headline grey--text text--darken-1">{{
                        $t("OrderView.cardFinalPriceJourney")
                      }}</span>
                    </v-col>

                    <div
                      class="d-flex justify-center align-center my-2 mx-4 pa-4 secondary rounded-lg"
                    >
                      <v-icon large color="white">mdi-car-connected</v-icon>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-show="item.finalPriceExtra">
              <v-card tile class="py-1 my-2 rounded-lg grey lighten-5">
                <v-card-text class="pa-0">
                  <v-row no-gutters>
                    <v-col class="py-1 ml-3">
                      <div class="display-1 grey--text text--darken-2">
                        {{
                          item.finalPriceExtra
                            ? item.finalPriceExtra + " " + item.currency
                            : "-"
                        }}
                      </div>
                      <span class="headline grey--text text--darken-1">{{
                        $t("OrderView.cardFinalPriceExtra")
                      }}</span>
                    </v-col>

                    <div
                      class="d-flex justify-center align-center my-2 mx-4 pa-4 secondary rounded-lg"
                    >
                      <v-icon large color="white">mdi-server-plus</v-icon>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-show="item.finalPriceDiscount">
              <v-card tile class="py-1 my-2 rounded-lg grey lighten-5">
                <v-card-text class="pa-0">
                  <v-row no-gutters>
                    <v-col class="py-1 ml-3">
                      <div class="display-1 grey--text text--darken-2">
                        {{
                          item.finalPriceDiscount
                            ? item.finalPriceDiscount + " " + item.currency
                            : "-"
                        }}
                      </div>
                      <span class="headline grey--text text--darken-1">{{
                        $t("OrderView.cardFinalPriceDiscount")
                      }}</span>
                    </v-col>

                    <div
                      class="d-flex justify-center align-center my-2 mx-4 pa-4 secondary rounded-lg"
                    >
                      <v-icon large color="white">mdi-sale-outline</v-icon>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-show="item.finalPriceTotal">
              <v-card tile class="py-1 my-2 rounded-lg grey lighten-5">
                <v-card-text class="pa-0">
                  <v-row no-gutters>
                    <v-col class="py-1 ml-3">
                      <div class="display-1 grey--text text--darken-2">
                        {{
                          item.finalPriceTotal
                            ? item.finalPriceTotal + " " + item.currency
                            : "-"
                        }}
                      </div>
                      <span
                        class="headline font-weight-bold grey--text text--darken-1"
                        >{{ $t("OrderView.cardFinalPriceTotal") }}</span
                      >
                    </v-col>

                    <div
                      class="d-flex justify-center align-center my-2 mx-4 pa-4 secondary rounded-lg"
                    >
                      <v-icon large color="white">mdi-cash-multiple</v-icon>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-subheader class="mt-8 font-weight-bold text-h5"
            >{{ $t("OrderView.subheaderPrice") }}
            <v-spacer></v-spacer>
            <v-switch
              v-model="displayCalculation"
              :label="
                displayCalculation
                  ? $t('OrderView.displayCalculationSwitchHide')
                  : $t('OrderView.displayCalculationSwitchShow')
              "
            ></v-switch>
          </v-subheader>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("OrderView.tblHeaderPriceName") }}
                </th>
                <th class="text-right">
                  {{ $t("OrderView.tblHeaderPriceEstimated") }}
                </th>
                <th class="text-right">
                  {{ $t("OrderView.tblHeaderPriceFinal") }}
                </th>
                <th class="text-right">
                  {{ $t("OrderView.tblHeaderPriceInfo") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="displayOnlyForAdmin && displayCalculation">
                <td>
                  Calculation:<br />F (typ sadzby),<br />W (sadzba za čakanie),
                  <br />Q (čas čakania nad limit),<br />S (sadzba za
                  vyzdvihnutie),<br />R (vzdialenosť vyzdvihnutia),<br />D
                  (sadzba za vzdialenosť),<br />T (sadzba za čas),<br />M
                  (minimálna cena)
                </td>
                <td class="text-right" v-html="item.estimatedCalculation"></td>
                <td class="text-right">-</td>
                <td class="text-right" v-html="item.infoCalculation"></td>
              </tr>
              <tr>
                <td>{{ $t("OrderView.tblPrice.locFrom") }}</td>
                <td class="text-right">
                  {{ item.estimatedLocFrom }}
                </td>
                <td class="text-right">
                  {{ item.finalLocFrom }}
                </td>
                <td class="text-right">
                  {{ item.infoLocFrom }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("OrderView.tblPrice.locTo") }}</td>
                <td class="text-right">
                  {{ item.estimatedLocTo }}
                </td>
                <td class="text-right">
                  {{ item.finalLocTo }}
                </td>
                <td class="text-right">
                  {{ item.infoLocTo }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("OrderView.tblPrice.distance") }}</td>
                <td class="text-right">
                  {{
                    item.estimatedDistance ? item.estimatedDistance + "m" : ""
                  }}
                  <!-- convert item.estimatedDistance (meters) to kilometers -->
                  <br />
                  {{
                    displayCalculation
                      ? item.estimatedDistance
                        ? "(" +
                          (item.estimatedDistance / 1000).toFixed(3) +
                          "km)"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.finalDistance ? item.finalDistance + "m" : "" }}
                  <br />
                  {{
                    displayCalculation
                      ? item.finalDistance
                        ? "(" + (item.finalDistance / 1000).toFixed(3) + "km)"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.infoDistance ? item.infoDistance + "m" : "" }}
                  <br />
                  {{
                    displayCalculation
                      ? item.infoDistance
                        ? "(" + (item.infoDistance / 1000).toFixed(3) + "km)"
                        : ""
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("OrderView.tblPrice.time") }}</td>
                <td class="text-right">
                  {{ item.estimatedTime ? item.estimatedTime + " s" : "" }}
                  <!-- convert item.estimatedTime (seconds) to minutes and seconds -->
                  <br />
                  {{
                    displayCalculation
                      ? item.estimatedTime
                        ? "(" +
                          Math.floor(item.estimatedTime / 60) +
                          "min " +
                          (item.estimatedTime % 60) +
                          "s)"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.finalTime ? item.finalTime + " s" : "" }}
                  <!-- convert item.finalTime (seconds) to minutes and seconds -->
                  <br />
                  {{
                    displayCalculation
                      ? item.finalTime
                        ? "(" +
                          Math.floor(item.finalTime / 60) +
                          "min " +
                          (item.finalTime % 60) +
                          "s)"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.infoTime ? item.infoTime + " s" : "" }}
                  <!-- convert item.infoTime (seconds) to minutes and seconds -->
                  <br />
                  {{
                    displayCalculation
                      ? item.infoTime
                        ? "(" +
                          Math.floor(item.infoTime / 60) +
                          "min " +
                          (item.infoTime % 60) +
                          "s)"
                        : ""
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceWaiting") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Price Waiting)"
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.estimatedPriceWaiting }}
                  <br />
                  {{
                    displayCalculation
                      ? item.estimatedCalculationArray
                        ? "(" +
                          (item.estimatedCalculationArray["W"]
                            ? item.estimatedCalculationArray["W"]
                            : "-") +
                          " x " +
                          (item.estimatedCalculationArray["Q"]
                            ? item.estimatedCalculationArray["Q"]
                            : "-") +
                          "min)"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.finalPriceWaiting }}
                  {{
                    item.estimatedDistance == item.finalDistance &&
                    item.estimatedPriceWaiting != item.finalPriceWaiting
                      ? "!"
                      : ""
                  }}
                  {{
                    item.infoDistance == item.finalDistance &&
                    item.infoPriceWaiting != item.finalPriceWaiting
                      ? "!!"
                      : ""
                  }}
                  <br />
                  {{
                    displayCalculation
                      ? item.finalCalculationArray
                        ? "(" +
                          (item.finalCalculationArray["W"]
                            ? item.finalCalculationArray["W"]
                            : "-") +
                          " x " +
                          (item.finalCalculationArray["Q"]
                            ? item.finalCalculationArray["Q"]
                            : "-") +
                          "min)"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.infoPriceWaiting }}<br />
                  {{
                    displayCalculation
                      ? item.infoCalculationArray
                        ? item.infoCalculationArray.length > 0
                          ? "(" +
                            (item.infoCalculationArray["W"]
                              ? item.infoCalculationArray["W"]
                              : "-") +
                            " x " +
                            (item.infoCalculationArray["Q"]
                              ? item.infoCalculationArray["Q"]
                              : "-") +
                            "min)"
                          : ""
                        : ""
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceStart") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Price Start) "
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.estimatedPriceStart }}<br />
                  {{
                    displayCalculation
                      ? item.estimatedCalculationArray
                        ? "(" +
                          (item.estimatedCalculationArray["S"]
                            ? item.estimatedCalculationArray["S"]
                            : "-") +
                          " x (" +
                          (item.estimatedCalculationArray["Rb"]
                            ? item.estimatedCalculationArray["Rb"] / 1000 + "km"
                            : "-") +
                          (item.estimatedCalculationArray["Rb"] &&
                          item.estimatedCalculationArray["Rd"]
                            ? " + "
                            : "") +
                          (item.estimatedCalculationArray["Rd"]
                            ? item.estimatedCalculationArray["Rd"] / 1000 + "km"
                            : "-") +
                          "))"
                        : ""
                      : ""
                  }}
                </td>
                <!-- parse Rb value Rb from item.estimatedCalculation (example "F:LOCALITY Rb:186334 Rd:190608 Q:0 W:0.50 S:0.44 D:0.33 T:0.11 M:2.00") -->
                <td class="text-right">
                  {{ item.finalPriceStart }}<br />
                  {{
                    displayCalculation
                      ? item.finalCalculationArray
                        ? "(" +
                          (item.finalCalculationArray["S"]
                            ? item.finalCalculationArray["S"]
                            : "-") +
                          " x (" +
                          (item.finalCalculationArray["Rb"]
                            ? item.finalCalculationArray["Rb"] / 1000 + "km"
                            : "-") +
                          (item.finalCalculationArray["Rb"] &&
                          item.finalCalculationArray["Rd"]
                            ? " + "
                            : "") +
                          (item.finalCalculationArray["Rd"]
                            ? item.finalCalculationArray["Rd"] / 1000 + "km"
                            : "-") +
                          "))"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.infoPriceStart }}<br />
                  {{
                    displayCalculation
                      ? item.infoCalculationArray
                        ? "(" +
                          (item.infoCalculationArray["S"]
                            ? item.infoCalculationArray["S"]
                            : "-") +
                          " x (" +
                          (item.infoCalculationArray["Rb"]
                            ? item.infoCalculationArray["Rb"] / 1000 + "km"
                            : "-") +
                          (item.infoCalculationArray["Rb"] &&
                          item.infoCalculationArray["Rd"]
                            ? " + "
                            : "") +
                          (item.infoCalculationArray["Rd"]
                            ? item.infoCalculationArray["Rd"] / 1000 + "km"
                            : "-") +
                          "))"
                        : ""
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceDriveDistance") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Price Drive Distance)"
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.estimatedPriceDriveDistance }}
                  <br />
                  {{
                    displayCalculation
                      ? item.estimatedCalculationArray
                        ? "(" +
                          (item.estimatedCalculationArray["D"]
                            ? item.estimatedCalculationArray["D"]
                            : "-") +
                          " x " +
                          (item.estimatedDistance
                            ? (item.estimatedDistance / 1000).toFixed(3) + "km"
                            : "-") +
                          ")"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.finalPriceDriveDistance }}
                  <br />
                  {{
                    displayCalculation
                      ? item.finalCalculationArray
                        ? "(" +
                          (item.finalCalculationArray["D"]
                            ? item.finalCalculationArray["D"]
                            : "-") +
                          " x " +
                          (item.finalDistance
                            ? (item.finalDistance / 1000).toFixed(3) + "km"
                            : "-") +
                          ")"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.infoPriceDriveDistance }}
                  <br />
                  {{
                    displayCalculation
                      ? item.infoCalculationArray
                        ? "(" +
                          (item.infoCalculationArray["D"]
                            ? item.infoCalculationArray["D"]
                            : "-") +
                          " x " +
                          (item.infoDistance
                            ? (item.infoDistance / 1000).toFixed(3) + "km"
                            : "-") +
                          ")"
                        : ""
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceDriveTime") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Price Drive Time)"
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.estimatedPriceDriveTime }}
                  <br />
                  {{
                    displayCalculation
                      ? item.estimatedCalculationArray
                        ? "(" +
                          (item.estimatedCalculationArray["T"]
                            ? item.estimatedCalculationArray["T"]
                            : "-") +
                          " x " +
                          (item.estimatedTime
                            ? (item.estimatedTime / 60).toFixed(3) + "min"
                            : "-") +
                          ")"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.finalPriceDriveTime }}
                  <br />
                  {{
                    displayCalculation
                      ? item.finalCalculationArray
                        ? "(" +
                          (item.finalCalculationArray["T"]
                            ? item.finalCalculationArray["T"]
                            : "-") +
                          " x " +
                          (item.finalTime
                            ? (item.finalTime / 60).toFixed(3) + "min"
                            : "-") +
                          ")"
                        : ""
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.infoPriceDriveTime }}
                  <br />
                  {{
                    displayCalculation
                      ? item.infoCalculationArray
                        ? "(" +
                          (item.infoCalculationArray["T"]
                            ? item.infoCalculationArray["T"]
                            : "-") +
                          " x " +
                          (item.infoTime
                            ? (item.infoTime / 60).toFixed(3) + "min"
                            : "-") +
                          ")"
                        : ""
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceDrive") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Price Drive)"
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.estimatedPriceDrive }}
                  <br />
                  {{
                    displayCalculation
                      ? "(" +
                        item.estimatedPriceDriveDistance +
                        " + " +
                        item.estimatedPriceDriveTime +
                        ")"
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.finalPriceDrive }}
                  <br />
                  {{
                    displayCalculation
                      ? "(" +
                        item.finalPriceDriveDistance +
                        " + " +
                        item.finalPriceDriveTime +
                        ")"
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.infoPriceDrive }}
                  <br />
                  {{
                    displayCalculation
                      ? item.infoCalculationArray
                        ? "(" +
                          item.infoPriceDriveDistance +
                          " + " +
                          item.infoPriceDriveTime +
                          ")"
                        : ""
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceJourney") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Price Journey)"
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.estimatedPriceJourney }}
                  <br />
                  {{
                    displayCalculation
                      ? "(" +
                        item.estimatedPriceStart +
                        " + " +
                        item.estimatedPriceDrive +
                        (item.estimatedCalculationArray
                          ? item.estimatedCalculationArray["M"]
                            ? " || " + item.estimatedCalculationArray["M"]
                            : "-"
                          : "") +
                        ")"
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.finalPriceJourney }}
                  {{
                    item.estimatedDistance == item.finalDistance &&
                    item.estimatedPriceJourney != item.finalPriceJourney
                      ? "!"
                      : ""
                  }}
                  {{
                    item.infoDistance == item.finalDistance &&
                    item.infoPriceJourney != item.finalPriceJourney
                      ? "!!"
                      : ""
                  }}
                  <br />
                  {{
                    displayCalculation
                      ? "(" +
                        item.finalPriceStart +
                        " + " +
                        item.finalPriceDrive +
                        (item.finalCalculationArray
                          ? item.finalCalculationArray["M"]
                            ? " || " + item.finalCalculationArray["M"]
                            : "-"
                          : "") +
                        ")"
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.infoPriceJourney }}
                  <br />
                  {{
                    displayCalculation
                      ? item.infoCalculationArray
                        ? "(" +
                          item.infoPriceStart +
                          " + " +
                          item.infoPriceDrive +
                          (item.infoCalculationArray
                            ? item.infoCalculationArray["M"]
                              ? " || " + item.infoCalculationArray["M"]
                              : "-"
                            : "") +
                          ")"
                        : ""
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceExtra") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Price Extra)"
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.estimatedPriceExtra }}
                </td>
                <td class="text-right">
                  {{ item.finalPriceExtra }}
                  {{
                    item.estimatedDistance == item.finalDistance &&
                    item.estimatedPriceExtra != item.finalPriceExtra
                      ? "!"
                      : ""
                  }}
                  {{
                    item.infoDistance == item.finalDistance &&
                    item.infoPriceExtra != item.finalPriceExtra
                      ? "!!"
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.infoPriceExtra }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceDiscount") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Discount) "
                      : ""
                  }}
                </td>
                <td class="text-right">
                  {{ item.estimatedPriceDiscount }}
                </td>
                <td class="text-right">
                  {{ item.finalPriceDiscount }}
                </td>
                <td class="text-right">
                  {{ item.infoPriceDiscount }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("OrderView.tblPrice.priceTotal") }}
                  {{
                    displayOnlyForAdmin && displayCalculation
                      ? "(Price Total)"
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.estimatedPriceTotal }}
                  <br />
                  {{
                    displayCalculation
                      ? "(" +
                        (item.estimatedPriceWaiting
                          ? item.estimatedPriceWaiting
                          : " 0 ") +
                        (item.estimatedPriceJourney
                          ? " + " + item.estimatedPriceJourney
                          : " + 0 ") +
                        (item.estimatedPriceExtra
                          ? " + " + item.estimatedPriceExtra
                          : " + 0 ") +
                        (item.estimatedPriceDiscount
                          ? " - " + item.estimatedPriceDiscount
                          : " - 0 ") +
                        ")"
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.finalPriceTotal }}
                  {{
                    item.estimatedDistance == item.finalDistance &&
                    item.estimatedPriceTotal != item.finalPriceTotal
                      ? "!"
                      : ""
                  }}
                  {{
                    item.infoDistance == item.finalDistance &&
                    item.infoPriceTotal != item.finalPriceTotal
                      ? "!!"
                      : ""
                  }}
                  <br />
                  {{
                    displayCalculation
                      ? "(" +
                        (item.finalPriceWaiting
                          ? item.finalPriceWaiting
                          : " 0 ") +
                        (item.finalPriceJourney
                          ? " + " + item.finalPriceJourney
                          : " + 0 ") +
                        (item.finalPriceExtra
                          ? " + " + item.finalPriceExtra
                          : " + 0 ") +
                        (item.finalPriceDiscount
                          ? " - " + item.finalPriceDiscount
                          : " - 0 ") +
                        ")"
                      : ""
                  }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ item.infoPriceTotal }}
                  <br />
                  {{
                    displayCalculation
                      ? "(" +
                        (item.infoPriceWaiting
                          ? item.infoPriceWaiting
                          : " 0 ") +
                        (item.infoPriceJourney
                          ? " + " + item.infoPriceJourney
                          : " + 0 ") +
                        (item.infoPriceExtra
                          ? " + " + item.infoPriceExtra
                          : " + 0 ") +
                        (item.infoPriceDiscount
                          ? " - " + item.infoPriceDiscount
                          : " - 0 ") +
                        ")"
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <!-- new session -->
          <v-subheader class="mt-8 font-weight-bold text-h5">{{
            $t("OrderView.subheaderPriceItems")
          }}</v-subheader>
          <!-- display list orderItemList with parameters:
                active
                cancelPriceFixation
                id
                name
                price
                quantity
                relatedId
                resetToDefualtPrice
                type
                unit
                unitPrice
          -->
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("OrderView.tblPriceItems.type") }}
                </th>
                <th class="text-left">
                  {{ $t("OrderView.tblPriceItems.name") }}
                </th>
                <th class="text-center">
                  {{ $t("OrderView.tblPriceItems.active") }}
                </th>
                <th class="text-center">
                  {{ $t("OrderView.tblPriceItems.cancelPriceFixation") }}
                </th>
                <th class="text-center">
                  {{ $t("OrderView.tblPriceItems.resetToDefualtPrice") }}
                </th>
                <th class="text-right">
                  {{ $t("OrderView.tblPriceItems.unitPrice") }}
                </th>
                <th class="text-right">
                  {{ $t("OrderView.tblPriceItems.quantity") }}
                </th>
                <th class="text-right">
                  {{ $t("OrderView.tblPriceItems.unit") }}
                </th>
                <th class="text-right">
                  {{ $t("OrderView.tblPriceItems.price") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in item.orderItemList" :key="item.id">
                <td
                  :title="item.createdAt"
                  :class="{
                    'font-weight-bold':
                      (item.type === 'PRICE_JOURNEY' ||
                        item.type === 'PRICE_WAITING') &&
                      item.active &&
                      item.price != 0,
                  }"
                >
                  {{ item.type }}
                </td>
                <td>{{ item.name }}</td>
                <!-- v-chip with item.active and check / unchecked ikon  -->
                <td class="text-center">
                  <v-icon v-if="item.active" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                  <!--<v-chip
                    v-if="item.active"
                    color="green"
                    text-color="white"
                    class="pa-1"
                    small
                  >
                    <v-icon small>mdi-check</v-icon>
                  </v-chip>
                  <v-chip
                    v-else
                    color="red"
                    text-color="white"
                    class="pa-1"
                    small
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-chip> -->
                </td>
                <!-- v-chip with item.cancelPriceFixation and check / unchecked ikon -->
                <td class="text-center">
                  <v-icon
                    v-if="item.cancelPriceFixation && item.active"
                    color="green"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon
                    v-else-if="!item.cancelPriceFixation && item.active"
                    color="red"
                  >
                    mdi-cancel
                  </v-icon>
                  <v-icon
                    v-else-if="item.cancelPriceFixation && !item.active"
                    color="grey"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="grey"> mdi-cancel </v-icon>
                  <!--<v-chip
                    v-if="item.cancelPriceFixation"
                    color="green"
                    text-color="white"
                    class="pa-1"
                    small
                  >
                    <v-icon small>mdi-check</v-icon>
                  </v-chip>
                  <v-chip
                    v-else
                    color="red"
                    text-color="white"
                    class="pa-1"
                    small
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-chip>-->
                </td>
                <!-- v-chip with item.resetToDefualtPrice and check / unchecked ikon -->
                <td class="text-center">
                  <v-icon
                    v-if="item.resetToDefualtPrice && item.active"
                    color="green"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon
                    v-else-if="!item.resetToDefualtPrice && item.active"
                    color="red"
                  >
                    mdi-cancel
                  </v-icon>
                  <v-icon
                    v-else-if="item.resetToDefualtPrice && !item.active"
                    color="grey"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="grey"> mdi-cancel </v-icon>
                  <!--<v-chip
                    v-if="item.resetToDefualtPrice"
                    color="green"
                    text-color="white"
                    class="pa-1"
                    small
                  >
                    <v-icon small>mdi-check</v-icon>
                  </v-chip>
                  <v-chip
                    v-else
                    color="red"
                    text-color="white"
                    class="pa-1"
                    small
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-chip>-->
                </td>
                <td class="text-right">{{ item.unitPrice }}</td>
                <td class="text-right">{{ item.quantity }}</td>
                <td class="text-right">{{ item.unit }}</td>
                <td
                  class="text-right"
                  :class="!item.active ? 'text-decoration-line-through' : ''"
                >
                  {{ item.price }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>

      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        right
        value="true"
        :color="snackbar.color"
      >
        {{ snackbar.message }}
      </v-snackbar>
    </div>

    <div v-else>
      <v-btn color="error" @click="getItem()"> {{ $t("btnReload") }}</v-btn>
    </div>
  </div>
</template>

<style scoped>
#map {
  height: 70vh;
}
</style>

<script>
import axios from "axios";
import * as moment from "moment/moment";

import {
  ChildrenChair,
  SpecialTransport,
  Animal,
  Autonomous,
  Luggage,
  Airconditioning,
  paymentTerminal,
  Smoking,
  Eco,
  Color,
  Type,
  Status,
} from "../enums/VehicleEnum";

const TYPE = {
  success: {
    color: "success",
    icon: "mdi-check-circle",
  },
  error: {
    color: "error",
    icon: "mdi-alert-circle",
  },
  info: {
    color: "info",
    icon: "mdi-information",
  },
  warning: {
    color: "warning",
    icon: "mdi-alert",
  },
  orderCreate: {
    color: "success",
    icon: "mdi-note-plus-outline",
  },
  requestedTime: {
    color: "info",
    icon: "mdi-timer-outline",
  },
  orderStatusChange: {
    color: "success",
    icon: "mdi-note-check-outline",
  },
  orderCancel: {
    color: "error",
    icon: "mdi-note-remove-outline",
  },
  orderWarning: {
    //napr. pri odobrati objednavky vodicovi
    color: "warning",
    icon: "mdi-note-alert-outline",
  },
  chat: {
    color: "warning",
    icon: "mdi-comment-text-multiple-outline",
  },
  waypoint: {
    color: "purple",
    icon: "mdi-transit-connection-variant",
  },
  waypointIssue: {
    color: "error",
    icon: "mdi-transit-connection-variant",
  },
  waiting: {
    color: "teal",
    icon: "mdi-clock-outline",
  },
  orderLog: {
    color: "grey",
    icon: "mdi-note-text-outline",
  },
  orderSentLog: {
    color: "brown",
    icon: "mdi-robot",
  },
};

export default {
  data() {
    return {
      //predvolena prva zalozka s indexom 0
      tabs: 0,
      //Mapa trasy
      map: null,
      //Defaultne centrum zobrazenia stredu vsetkych map
      mapCenter: { lat: 48.148, lng: 18.127 },
      mapZoom: 12,
      //nazoomovanie na vsetky objekty na mape
      mapBounds: null,
      //zobrazenie vybranych objektov iba pre admina
      displayOnlyForAdmin: false,
      //robrazenie rozšírených prepočtov ceny
      displayCalculation: false,
      //redukovanie bodov na mape, ak je dlha trasa, aby sa zobrazovala rychlejsie a nesekalo obrazovku
      reducePositions: true,
      //zobrazenie rozbalenych expansion panelov na prvej zalozke
      infoExpansionPanel: [7, 8],
      //enums
      ChildrenChair,
      SpecialTransport,
      Animal,
      Autonomous,
      Luggage,
      Airconditioning,
      Smoking,
      Eco,
      paymentTerminal,
      Color,
      Type,
      Status,
      // Ikona k zoznamu adries objednavky
      addressIcons: {
        BOARD: "fas fa-street-view",
        DESTINATION: "fas fa-flag-checkered",
        TRANSIT: "mdi-transit-connection-variant",
      },
      //
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      itemId: null,
      item: {
        id: "",
        status: "UNDEFINED",
        type: "UNDEFINED",
        source: "UNDEFINED",
        client: { mark: "NEW" },
        driver: {},
        estimatedCalculationArray: [],
        finalCalculationArray: [],
        infoCalculationArray: [],
        orderItemList: [],
        waypointList: [
          {
            id: null,
            status: null,
            ordering: null,
            latitude: null,
            longitude: null,
            address: "-",
            type: "BOARD",
            localityId: null,
          },
        ],
      },
      //zoznam vodičov z pamate
      driverList: [],

      //cachedItem: null,

      defaultStand: {
        id: null,
        name: "-",
        internalName: "-",
        latitude: null,
        longitude: null,
      },
      //Zobrazenie prejazdových bodov na mape
      waypointMarker: [],
      routeLine: [],
      routeAccepted: [],
      routeWaiting: [],
      routeInProgress: [],

      timelineItems: [
        /*{
          id: 22,
          type: TYPE.error,
          title: "KONZOLA",
          text: "komplet cela konzola",
        },     */
      ],

      // URL na simulovanie trasy
      urlGoogleMap: "",
      urlOpenStreetMap: "",
      urlOsmRouting: "",
      urlGoogleMapWithStand: "",
      urlOpenStreetMapWithStand: "",
      urlOsmRoutingWithStand: "",
      urlGoogleMapReal: "",
      urlOpenStreetMapReal: "",
      urlOsmRoutingReal: "",

      //Zobrazenie cenovych lokalit na mape
      priceLocalityList: [],
      localityShapes: [],
      //Zaciatok trasy
      startPositon: null,
      //Koniec trasy
      endPosition: null,
    };
  },

  computed: {
    //Usporiadanie prejazdovych bodov od nastúpenia, cez prejazdy, po ciel
    orderDetailWaypointList: function () {
      if (this.item.waypointList) {
        var value = this.item.waypointList;
        return value.sort((a, b) => a.ordering - b.ordering);
      }
    },
    //formatDate() {
    //return moment(this.item.validFrom).local();
    //}
    orderDetailChatList: function () {
      if (this.item.chatList) {
        var value = this.item.chatList;
        var tempData = [];
        //Doplnenie zoznamu o data, ktore sa nepovinne nachadzaju v chatList-from
        value.forEach((item) => {
          if (item.from) {
            item.fromId = item.from.id;
            item.fromName = item.from.name;
            tempData.push(item);
          }
        });
        value = tempData;
        return value.sort((a, b) => a.ordering - b.ordering);
      }
    },
  },

  watch: {
    infoExpansionPanel: function () {
      //Najprv potrebujeme usporiadat expansion panely, ak su domiesane, tak nefunguje otvaranie panelov
      var orderedInfoExpansionPanel = [];
      if (this.infoExpansionPanel.length > 0) {
        orderedInfoExpansionPanel = orderedInfoExpansionPanel.concat(
          this.infoExpansionPanel
        );
      }
      orderedInfoExpansionPanel.sort((a, b) => a - b);
      console.log(
        "orderedInfoExpansionPanel",
        JSON.stringify(orderedInfoExpansionPanel)
      );
      //zapamatat do cookies, aby sa zobrazovali konkretne expansion panely
      this.setCookie(
        "infoExpansionPanel",
        JSON.stringify(orderedInfoExpansionPanel),
        30
      );
    },
  },

  created() {
    var tempInfoExpansionPanel = this.getCookie("infoExpansionPanel")
      ? JSON.parse(this.getCookie("infoExpansionPanel"))
      : [];
    if (tempInfoExpansionPanel.length > 0) {
      this.infoExpansionPanel = tempInfoExpansionPanel;
    }
    var centerOfMap = this.getCookie("centerOfMap")
      ? JSON.parse(this.getCookie("centerOfMap"))
      : {};
    if (centerOfMap.lat && centerOfMap.lng) {
      this.mapCenter = centerOfMap;
    }
    var zoomOfMap = this.getCookie("zoomOfMap")
      ? JSON.parse(this.getCookie("zoomOfMap"))
      : 0;
    if (zoomOfMap) {
      this.mapZoom = zoomOfMap;
    }
    //Nacitanie role podla ktorej sa potom zobrazuju niektore extra polia
    this.getUserRole();
  },

  mounted() {
    this.loadStorageDrivers();
    this.initMap();
    this.itemId = this.$route.params.id;
    this.getItem();
  },
  methods: {
    loadStorageDrivers() {
      var tempDriverList = JSON.parse(localStorage.getItem("driverList"));
      if (tempDriverList) {
        this.driverList = tempDriverList;
      }
    },

    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    refreshItem() {
      //remove waypointMarker from map
      for (var key in this.waypointMarker) {
        if (this.waypointMarker.hasOwnProperty(key)) {
          //console.log("key", key);
          //console.log("this.waypointMarker[key]", this.waypointMarker[key]);
          this.waypointMarker[key].setMap(null);
          this.waypointMarker[key] = null;
        }
      }
      this.waypointMarker = [];

      //remove routeLine from map
      this.routeLine.setMap(null);
      this.routeLine = [];

      //remove  this.positionMarker from map
      for (var key in this.positionMarker) {
        if (this.positionMarker.hasOwnProperty(key)) {
          //console.log("key", key);
          //console.log("this.positionMarker[key]", this.positionMarker[key]);
          this.positionMarker[key].setMap(null);
          this.positionMarker[key] = null;
        }
      }
      this.positionMarker = [];
      this.routeAccepted.setMap(null);
      this.routeAccepted = [];
      this.routeWaiting.setMap(null);
      this.routeWaiting = [];
      this.routeInProgress.setMap(null);

      // remove timeline objects
      this.timelineItems = [];

      this.getItem();
    },
    getItem() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/order/${this.itemId}/history`
        )
        .then((response) => {
          this.item = response.data;
          //this.cachedItem = Object.assign({}, this.item);
          //console.log("tabs", this.tabs);
          if (response.data.waypointList) {
            this.setWaypointMarkers(response.data.waypointList);
            //console.log("item.waypointList", item.waypointList);
            this.simulateRoute(response.data.waypointList);
            //simulacie su dostupne iba pre admina, nacitava sa vzdy aj defaultne stanoviste, tak je tu podmienka,
            //aby sme zbytone nerobili requesty u bezneho dispecera
            if (this.displayOnlyForAdmin == true) {
              this.simulateRouteFromStand(response.data.waypointList);
            }
            //mapa oblasti je dostupna pre admina
            if (this.displayOnlyForAdmin == true) {
              this.getItemsLocalityPrice();
            }
          }
          if (response.data.positionList) {
            if (response.data.positionList.length > 1) {
              this.setPositionMarkers(response.data.positionList);
              this.analyzePosition(response.data.positionList);
              //console.log("item.waypointList", item.waypointList);
            }
          }
          this.setTimeline();
          //parse value response.data.estimatedCalculation in structure "F:0 Rb:186334 Rd:190608 Q:0 W:0.50 S:0.44 D:0.33 T:0.11 M:2.00"
          this.item.estimatedCalculationArray = this.parseCalculation(
            response.data.estimatedCalculation
          );
          //Neuchovavame v DB hodnotu finalCalculation, lebo je to na konci rovné Estimated alebo Info
          if (response.data.finalPriceTotal) {
            if (
              response.data.estimatedPriceTotal == response.data.finalPriceTotal
            ) {
              this.item.finalCalculationArray = this.parseCalculation(
                response.data.estimatedCalculation
              );
            } else {
              this.item.finalCalculationArray = this.parseCalculation(
                response.data.infoCalculation
              );
            }
          }
          this.item.infoCalculationArray = this.parseCalculation(
            response.data.infoCalculation
          );
          //Nahradit v estimatedCalculation a infoCalculation medzery za znak pre novy riadok
          if (this.item.estimatedCalculation) {
            this.item.estimatedCalculation =
              this.item.estimatedCalculation.replace(/ /g, "<br />");
          }
          if (this.item.infoCalculation) {
            this.item.infoCalculation = this.item.infoCalculation.replace(
              / /g,
              "<br />"
            );
          }
          //console.log("item", this.item);
          //usporiadat orderItemList podla createdAt datumu v ISO formate
          this.item.orderItemList.sort(function (a, b) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          });
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
          console.log("error", e);
        });
    },
    parseCalculation(input) {
      if (input) {
        var temp = input.split(" ");
        var temp2 = [];
        var temp3 = [];
        temp.forEach((item) => {
          temp2 = item.split(":");
          temp3[temp2[0]] = temp2[1];
        });
        //console.log("temp3", temp3);
        return temp3;
      } else {
        return [];
      }
    },

    initMap() {
      //console.log("Map - start initiation");
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.mapCenter, //the center of the map
        zoom: this.mapZoom, //the zoom level of the map
        maxZoom: this.displayOnlyForAdmin ? 18 : 17, //17
        minZoom: 7,
        streetViewControl: false,
        fullscreenControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP, // typ mapy s cestami
        disableDefaultUI: true,
        mapTypeControl: true,
        /*mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_LEFT, 
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.HYBRID,
          ],
        },*/
        zoomControl: true,
        /*zoomControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //BOTTOM_LEFT
        },*/
        scaleControl: false,
      });
      //console.log("Map initiated");
      this.mapBounds = new google.maps.LatLngBounds();
    },

    setWaypointMarkers(waypointList) {
      //translation
      var addressMarker_BOARD = this.$t("OrderView.addressMarker_BOARD");
      var addressMarker_DESTINATION = this.$t(
        "OrderView.addressMarker_DESTINATION"
      );
      var addressMarker_TRANSIT1 = this.$t("OrderView.addressMarker_TRANSIT1");
      var addressMarker_TRANSIT2 = this.$t("OrderView.addressMarker_TRANSIT2");
      var addressMarker_TRANSIT3 = this.$t("OrderView.addressMarker_TRANSIT3");
      var addressMarker_TRANSIT4 = this.$t("OrderView.addressMarker_TRANSIT4");
      var addressMarker_TRANSIT = this.$t("OrderView.addressMarker_TRANSIT");

      //console.log("start setWaypointMarkers", waypointList);
      var map = this.map;
      var markerPosition;
      var iconImage;
      var markerTitle;
      var addressType = "TRANSIT";
      var waypointTransitCounter = 0;
      var waypointMarker = [];
      var mapBounds = this.mapBounds;
      var drivePathCoordinates = [];

      waypointList.sort((a, b) => a.ordering - b.ordering);
      waypointList.forEach(function (addressObject) {
        //var addressObject = waypointList[0];
        //console.log("addressObject", addressObject);
        if (addressObject.status != "DISABLED") {
          //console.log("addressObject.address", addressObject.address);
          if (addressObject.type == "TRANSIT") {
            //console.log("waypoint: Transit");
            waypointTransitCounter = ++waypointTransitCounter;
            addressType = addressObject.type + waypointTransitCounter;
          } else {
            addressType = addressObject.type;
          }

          //console.log("setWaypointMarker: ", addressObject);
          //console.log("addressObject: ", addressObject);
          if (
            addressObject.latitude != null &&
            addressObject.longitude != null /*&&
          (!this.waypointMarker[addressType] ||
            this.waypointMarker[addressType] == null)*/
          ) {
            //nadefinovat novy marker ak neexistuje
            markerPosition = new google.maps.LatLng(
              addressObject.latitude,
              addressObject.longitude
            );
            mapBounds.extend(markerPosition);
            drivePathCoordinates.push({
              lat: addressObject.latitude,
              lng: addressObject.longitude,
            });

            //console.log("markerPosition", markerPosition);
            switch (addressType) {
              case "BOARD":
                //console.log("The BOARD!!");
                iconImage = require("@/assets/markers/marker_boarding.png");
                markerTitle = addressMarker_BOARD;
                break;
              case "DESTINATION":
                //console.log("The DESTINATION!!");
                iconImage = require("@/assets/markers/marker_destination.png");
                markerTitle = addressMarker_DESTINATION;
                break;
              case "TRANSIT1":
                //console.log("TRANSIT1!!");
                iconImage = require("@/assets/markers/marker_transit1.png");
                markerTitle = addressMarker_TRANSIT1;
                break;
              case "TRANSIT2":
                //console.log("TRANSIT2!!");
                iconImage = require("@/assets/markers/marker_transit2.png");
                markerTitle = addressMarker_TRANSIT2;
                break;
              case "TRANSIT3":
                //console.log("The TRANSIT3!!");
                iconImage = require("@/assets/markers/marker_transit3.png");
                markerTitle = addressMarker_TRANSIT3;
                break;
              case "TRANSIT4":
                //console.log("The TRANSIT4!!");
                iconImage = require("@/assets/markers/marker_transit4.png");
                markerTitle = addressMarker_TRANSIT4;
                break;
              case "TRANSIT":
                //Fallback, ak by z nejakeho dovodu bolo viac ako 4 typu TRANSIT,
                //console.log("TRANSIT!!");
                iconImage = require("@/assets/markers/marker_transit4.png");
                markerTitle = addressMarker_TRANSIT;
                break;
              default:
                console.log("Any!!");
            }
            //nadefinovat novy marker ak neexistuje
            //console.log("start making waypoint marker");
            waypointMarker[addressType] = new google.maps.Marker({
              position: markerPosition,
              map: map,
              animation: google.maps.Animation.DROP,
              title: markerTitle,
              icon: {
                url: iconImage,
                size: new google.maps.Size(33, 47),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(16, 47),
              },
              shape: {
                coords: [
                  0, 13, 5, 5, 12, 1, 16, 0, 21, 1, 29, 5, 32, 13, 32, 19, 16,
                  47, 0, 19,
                ],
                type: "poly",
              },
            });
            //map.setCenter(markerPosition);
          }
        }
      });
      this.waypointMarker = waypointMarker;
      this.mapBounds = mapBounds;
      //this.map.fitBounds(mapBounds);
      this.routeLine = new google.maps.Polyline({
        path: drivePathCoordinates,
        map: map,
        geodesic: true,
        strokeColor: "#E040FB",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
    },
    analyzePosition(positionList) {
      //console.log("start analyzePosition", positionList);
      positionList.sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
      );
      //console.log("positionList", positionList);
      //count distances between positions
      var distance = 0;
      var distanceList = [];
      var partialDistance = 0;
      var timeInProgress = 0;
      var a;
      var c;
      var dLat;
      var dLng;
      var lat1;
      var lat2;
      var lng1;
      var lng2;
      var checkInfo = "";
      var checkInfoAll = "";
      var R = 6371000; // radius of earth in m
      //console.log("positionList.length", positionList.length);
      //Kontrola ci ma positionObject spravny orderStatus
      positionList.forEach(function (positionObject) {
        checkInfo = "";
        //console.log("positionObject", positionObject);
        if (
          positionObject.orderStatus == "ACCEPTED" ||
          positionObject.orderStatus == "WAITING" ||
          positionObject.orderStatus == "IN_PROGRESS" ||
          positionObject.orderStatus == "FINISHED"
        ) {
          checkInfo = "";
        } else {
          checkInfo =
            "Poloha " +
            positionObject.id +
            " bola zaznamenaná pre nesprávny Status objednávky (" +
            positionObject.orderStatus +
            ")." +
            "\n";
        }

        if (
          positionObject.orderStatus == "IN_PROGRESS" &&
          positionObject.latitude != null &&
          positionObject.longitude != null
        ) {
          if (distanceList.length > 0) {
            lat1 = positionObject.latitude;
            lng1 = positionObject.longitude;
            lat2 = distanceList[distanceList.length - 1].latitude;
            lng2 = distanceList[distanceList.length - 1].longitude;
            dLat = (lat2 - lat1) * (Math.PI / 180);
            dLng = (lng2 - lng1) * (Math.PI / 180);
            a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(lat1 * (Math.PI / 180)) *
                Math.cos(lat2 * (Math.PI / 180)) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2);
            c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            partialDistance = R * c; // Distance in m
            //console.log("partialDistance", partialDistance);
            distance += partialDistance;

            /*partialDistance = this.computeSphericalDistance(
              positionObject.latitude,
              positionObject.longitude,
              distanceList[distanceList.length - 1].latitude,
              distanceList[distanceList.length - 1].longitude
            );*/

            //seconds between positions
            var secondsBetweenPositions =
              (new Date(positionObject.recordedAt) -
                new Date(distanceList[distanceList.length - 1].recordedAt)) /
              1000;
            //console.log("secondsBetweenPositions", secondsBetweenPositions);
            //console.log("partialDistance", partialDistance);
            //count distance predicted by speed
            var predictedDistance =
              secondsBetweenPositions * positionObject.speed;
            //console.log("predictedDistance", predictedDistance);
            //trashold for difference between predicted and actual distance
            var trashold = partialDistance * 0.2 - partialDistance;
            //round to 0 decimal places
            trashold = Math.round(trashold);
            //positive trashold
            if (trashold < 0) {
              //negative trashold - todo - zmenit na absolutnu hodnotu
              trashold = -trashold;
            }
            //pridat 100m k trasholdu
            trashold += 100;
            //console.log("trashold", trashold);
            //compare predicted distance with real distance
            if (
              predictedDistance - partialDistance > trashold ||
              predictedDistance - partialDistance < -trashold
            ) {
              checkInfo +=
                "Predpokladaná vzdialenosť (" +
                predictedDistance +
                "m) je výrazne odlišná ako skutočná vzdialenosť (" +
                partialDistance +
                "m)." +
                "\n";
            }
            //console.log("secondsBetweenPositions", secondsBetweenPositions);
            if (secondsBetweenPositions > 30 || secondsBetweenPositions < -30) {
              checkInfo =
                checkInfo +
                "Poloha ID " +
                positionObject.id +
                " bola zaznamenaná viac ako 30 sekúnd pred predchádzajúcou polohou (" +
                secondsBetweenPositions +
                "s)." +
                "\n";
            }
            //seconds between createdAt and recordedAt
            var secondsBetweenCreatedAndRecorded =
              (new Date(positionObject.createdAt) -
                new Date(positionObject.recordedAt)) /
              1000;
            // console.log("secondsBetweenCreatedAndRecorded",secondsBetweenCreatedAndRecorded);
            if (
              secondsBetweenCreatedAndRecorded > 60 ||
              secondsBetweenCreatedAndRecorded < -60
            ) {
              checkInfo =
                checkInfo +
                "Poloha ID " +
                positionObject.id +
                " bola zaznamenaná o viac ako 60 sekúnd oproti vytvoreniu polohy v DB (" +
                secondsBetweenCreatedAndRecorded +
                "s)." +
                "\n";
              //Vodič možno nemá aktuálny čas alebo je odosielanie polohy značne spomalané napr. internetovým pripojením.
            }

            distanceList.push({
              id: positionObject.id,
              latitude: positionObject.latitude,
              longitude: positionObject.longitude,
              distance: partialDistance,
              predictedDistance: predictedDistance,
              trashold: trashold,
              createdAt: positionObject.createdAt,
              recordedAt: positionObject.recordedAt,
              secondsBetweenPositions: secondsBetweenPositions,
              secondsBetweenCreatedAndRecorded:
                secondsBetweenCreatedAndRecorded,
              orderStatus: positionObject.orderStatus,
              checkInfo: checkInfo,
            });
          } else {
            distanceList.push({
              id: positionObject.id,
              latitude: positionObject.latitude,
              longitude: positionObject.longitude,
              distance: 0,
              predictedDistance: 0,
              trashold: 0,
              createdAt: positionObject.createdAt,
              recordedAt: positionObject.recordedAt,
              secondsBetweenPositions: 0,
              secondsBetweenCreatedAndRecorded:
                secondsBetweenCreatedAndRecorded,
              orderStatus: positionObject.orderStatus,
              checkInfo: checkInfo,
            });
          }
        }
        checkInfoAll =
          checkInfoAll + (checkInfo.length > 0 ? checkInfo + "\n" : "");
      });
      if (distanceList.length > 1) {
        var startPosition = distanceList[0];
        var endPosition = distanceList[distanceList.length - 1];
        this.startPosition = startPosition;
        this.endPosition = endPosition;
        this.simulateRouteReal(startPosition, endPosition);
      }
      //console.log("distanceList", distanceList);
      //seconds between progressAt and finishedAt
      var timeInProgress =
        (new Date(this.item.finishedAt) - new Date(this.item.progressAt)) /
        1000;
      //console.log("timeInProgress", timeInProgress);
      //console.log("timeInProgress s navysenim",Math.round(timeInProgress * 1.1515 * 1000) / 1000);
      //ak je rozdiel viac ako 1 sekunda, zaloguj
      if (this.item.infoTime == null) {
        this.item.infoTime = 0;
      }
      if (
        timeInProgress - this.item.infoTime > 1 ||
        timeInProgress - this.item.infoTime < -1
      ) {
        checkInfoAll =
          checkInfoAll +
          "Čas trvania objednávky (" +
          timeInProgress +
          "s) nie je rovnaký ako čas podľa servera (" +
          this.item.infoTime +
          "s)." +
          "\n";
      }
      console.log("distance", distance);
      console.log(
        "distance s navysenim",
        Math.round(distance * 1.1515 * 1000) / 1000
      );
      if (this.item.infoDistance == null) {
        this.item.infoDistance = 0;
      }
      //ak je rozdiel viac ako 1 meter, zaloguj
      if (
        distance - this.item.infoDistance > 1 ||
        distance - this.item.infoDistance < -1
      ) {
        checkInfoAll =
          checkInfoAll +
          "Finálna vzdialenosť podľa bodov (" +
          distance +
          "m) je odlišná ako vzdialenosť podľa servera (" +
          this.item.infoDistance +
          "m)." +
          "\n";
      }
      if (checkInfoAll.length > 0)
        checkInfoAll = "Výsledok kontroly:\n" + checkInfoAll;
      else checkInfoAll = "Výsledok kontroly: OK";
      console.log(checkInfoAll);
    },
    computeSphericalDistance(lat1, lng1, lat2, lng2) {
      var R = 6371000; // Radius of the earth in m
      var dLat = (lat2 - lat1) * (Math.PI / 180);
      var dLng = (lng2 - lng1) * (Math.PI / 180);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in m
      return d;
    },

    setPositionMarkers(positionList) {
      //translations
      var markerTitleId = this.$t("OrderView.markerTitleId");
      var markerTitleOrderStatus = this.$t("OrderView.markerTitleOrderStatus");
      var markerTitleDriverId = this.$t("OrderView.markerTitleDriverId");
      var markerTitleSpeed = this.$t("OrderView.markerTitleSpeed");
      var markerTitleCreatedAt = this.$t("OrderView.markerTitleCreatedAt");
      var markerTitleRecordedAt = this.$t("OrderView.markerTitleRecordedAt");

      var map = this.map;
      var markerPosition;
      var positionMarker = [];
      var markerColor;
      var markerTitle;
      var markerLabel;
      var mapBounds = this.mapBounds;
      var drivePathCoordinatesAccepted = [];
      var drivePathCoordinatesWaiting = [];
      var drivePathCoordinatesInProgress = [];

      var routeAccepted = this.routeAccepted;
      var routeWaiting = this.routeWaiting;
      var routeInProgress = this.routeInProgress;
      var waitingList = this.item.waitingList;

      positionList.sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
      );
      //if more than 200 positions, reduce positions
      if (positionList.length > 200 && this.reducePositions == true) {
        var positionListReduced = [];
        positionList.forEach(function (positionObject, index) {
          if (index % Math.round(positionList.length / 100, 0) == 0) {
            positionListReduced.push(positionObject);
          }
        });
        positionList = positionListReduced;
      }
      //console.log("positionList", positionList);
      var waitingMark = false;
      positionList.forEach(function (positionObject) {
        waitingMark = false;
        /*console.log(
          "positionObject",
          positionObject.id + " " + positionObject.createdAt
        );*/
        //loop waitingList check if createdAt positionObject is between startedAt and finishedAt waitingList.
        waitingList.forEach(function (waitingObject) {
          //console.log("waitingObject", waitingObject);
          if (
            new Date(waitingObject.startedAt) <=
              new Date(positionObject.createdAt) &&
            new Date(waitingObject.finishedAt) >=
              new Date(positionObject.createdAt)
          ) {
            //console.log("waitingObject", waitingObject);
            //console.log("positionObject", positionObject);
            waitingMark = true;
          }
        });

        if (
          positionObject.latitude != null &&
          positionObject.longitude != null
        ) {
          markerPosition = new google.maps.LatLng(
            positionObject.latitude,
            positionObject.longitude
          );
          //console.log("markerPosition", markerPosition);
          mapBounds.extend(markerPosition);
          markerTitle =
            markerTitleId +
            ": " +
            positionObject.id +
            " | " +
            markerTitleOrderStatus +
            ": " +
            positionObject.orderStatus +
            " \n" +
            (waitingMark ? "! Waiting - Čakačka \n" : "") +
            markerTitleDriverId +
            ": " +
            positionObject.driverId +
            " \n" +
            markerTitleSpeed +
            ": " +
            positionObject.speed +
            " \n" +
            markerTitleCreatedAt +
            ": " +
            positionObject.createdAt +
            " \n" +
            markerTitleRecordedAt +
            ": " +
            positionObject.recordedAt;
          //console.log("markerTitle", markerTitle);
          if (positionObject.orderStatus) {
            if (positionObject.orderStatus == "ACCEPTED") {
              markerColor = "#FFFF50";
              markerLabel = "A" + (waitingMark ? "!" : "");
              drivePathCoordinatesAccepted.push({
                lat: positionObject.latitude,
                lng: positionObject.longitude,
              });
            } else if (positionObject.orderStatus == "WAITING") {
              markerColor = "#FF9933";
              markerLabel = "W" + (waitingMark ? "!" : "");
              drivePathCoordinatesWaiting.push({
                lat: positionObject.latitude,
                lng: positionObject.longitude,
              });
            } else if (positionObject.orderStatus == "IN_PROGRESS") {
              markerColor = "#9DC9FF";
              markerLabel = "P" + (waitingMark ? "!" : "");
              drivePathCoordinatesInProgress.push({
                lat: positionObject.latitude,
                lng: positionObject.longitude,
              });
            } else if (positionObject.orderStatus == "FINISHED") {
              markerColor = "#6599FF";
              markerLabel = "F" + (waitingMark ? "!" : "");
            } else {
              markerColor = "#ff0000";
              markerLabel = "?" + (waitingMark ? "!" : "");
            }
          } else {
            markerColor = "#ff0000";
            markerLabel = "?" + (waitingMark ? "!" : "");
          }

          //console.log("markerLabel", markerLabel);
          positionMarker[positionObject.id] = new google.maps.Marker({
            position: markerPosition,
            map: map,
            title: markerTitle,
            icon: {
              //path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
              path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
              fillColor: markerColor,
              fillOpacity: 0.85,
              strokeColor: waitingMark ? "#F22" : "#222",
              strokeWeight: waitingMark ? 2 : 1,
              scale: waitingMark ? 1.3 : 1.2,
              anchor: new google.maps.Point(0, 0),
              labelOrigin: new google.maps.Point(0, -28),
            },
            label: markerLabel,
          });
        }
      });
      this.positionMarker = positionMarker;
      this.mapBounds = mapBounds;
      //   this.map.fitBounds(mapBounds);
      routeAccepted = new google.maps.Polyline({
        path: drivePathCoordinatesAccepted,
        map: map,
        geodesic: true,
        strokeColor: "#FFFF50",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      routeWaiting = new google.maps.Polyline({
        path: drivePathCoordinatesWaiting,
        map: map,
        geodesic: true,
        strokeColor: "#FF9933",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      routeInProgress = new google.maps.Polyline({
        path: drivePathCoordinatesInProgress,
        map: map,
        geodesic: true,
        strokeColor: "#9DC9FF",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      this.routeAccepted = routeAccepted;
      this.routeWaiting = routeWaiting;
      this.routeInProgress = routeInProgress;
    },

    //SIMULACIA TRASY
    simulateRoute(waypointList) {
      // https://www.google.com/maps/dir/48.216894,18.605084/48.208726,18.605525/data=!3m1!4b1!4m2!4m1!3e0
      // https://www.openstreetmap.org/directions?engine=fossgis_osrm_car&route=48.216894,18.605084;48.208726,18.605525
      // https://routing.taxximo.com/route/v1/driving/18.605084,48.216894;18.605525,48.208726?steps=false
      var urlGoogleMap = "https://www.google.com/maps/dir/";
      var urlGoogleMapPositions = "";
      var urlGoogleMapSuffix = "/data=!3m1!4b1!4m2!4m1!3e0";
      var urlOpenStreetMap =
        "https://www.openstreetmap.org/directions?engine=fossgis_osrm_car&route=";
      var urlOpenStreetMapPositions = "";
      var urlOsmRouting = "https://routing.taxximo.com/route/v1/driving/";
      var urlOsmRoutingPositions = "";
      var urlOsmRoutingSuffix = "?steps=false";

      waypointList.sort((a, b) => a.ordering - b.ordering);
      waypointList.forEach(function (addressObject) {
        if (addressObject.status != "DISABLED") {
          if (
            addressObject.latitude != null &&
            addressObject.longitude != null
          ) {
            urlGoogleMapPositions =
              urlGoogleMapPositions +
              addressObject.latitude +
              "," +
              addressObject.longitude +
              "/";
            urlOpenStreetMapPositions =
              urlOpenStreetMapPositions +
              addressObject.latitude +
              "," +
              addressObject.longitude +
              ";";
            urlOsmRoutingPositions =
              urlOsmRoutingPositions +
              addressObject.longitude +
              "," +
              addressObject.latitude +
              ";";
          }
        }
      });
      urlGoogleMapPositions = urlGoogleMapPositions.substring(
        0,
        urlGoogleMapPositions.length - 1
      ); // remove last ";"
      urlOpenStreetMapPositions = urlOpenStreetMapPositions.substring(
        0,
        urlOpenStreetMapPositions.length - 1
      ); // remove last ";"
      //console.log("urlOsmRoutingPositions1", urlOsmRoutingPositions);
      urlOsmRoutingPositions = urlOsmRoutingPositions.substring(
        0,
        urlOsmRoutingPositions.length - 1
      ); // remove last ";"
      //console.log("urlOsmRoutingPositions2", urlOsmRoutingPositions);
      urlGoogleMap = urlGoogleMap + urlGoogleMapPositions + urlGoogleMapSuffix;
      urlOpenStreetMap = urlOpenStreetMap + urlOpenStreetMapPositions;
      urlOsmRouting =
        urlOsmRouting + urlOsmRoutingPositions + urlOsmRoutingSuffix;
      this.urlGoogleMap = urlGoogleMap;
      this.urlOpenStreetMap = urlOpenStreetMap;
      this.urlOsmRouting = urlOsmRouting;
      //console.log("urlGoogleMap", urlGoogleMap);
      //console.log("urlOpenStreetMap", urlOpenStreetMap);
      //console.log("urlOsmRouting", urlOsmRouting);
    },
    //simulateRouteStraight with startPosition and endPosition
    simulateRouteReal(startPosition, endPosition) {
      if (startPosition == null || endPosition == null) return;
      if (
        startPosition.latitude == null ||
        startPosition.longitude == null ||
        endPosition.latitude == null ||
        endPosition.longitude == null
      ) {
        return;
      }
      var urlGoogleMap = "https://www.google.com/maps/dir/";
      var urlGoogleMapPositions = "";
      var urlGoogleMapSuffix = "/data=!3m1!4b1!4m2!4m1!3e0";
      var urlOpenStreetMap =
        "https://www.openstreetmap.org/directions?engine=fossgis_osrm_car&route=";
      var urlOpenStreetMapPositions = "";
      var urlOsmRouting = "https://routing.taxximo.com/route/v1/driving/";
      var urlOsmRoutingPositions = "";
      var urlOsmRoutingSuffix = "?steps=false";
      urlGoogleMapPositions =
        startPosition.latitude + "," + startPosition.longitude + "/";
      urlGoogleMapPositions =
        urlGoogleMapPositions +
        endPosition.latitude +
        "," +
        endPosition.longitude;
      urlOpenStreetMapPositions =
        startPosition.latitude +
        "," +
        startPosition.longitude +
        ";" +
        endPosition.latitude +
        "," +
        endPosition.longitude;
      urlOsmRoutingPositions =
        startPosition.longitude +
        "," +
        startPosition.latitude +
        ";" +
        endPosition.longitude +
        "," +
        endPosition.latitude;
      urlGoogleMap = urlGoogleMap + urlGoogleMapPositions + urlGoogleMapSuffix;
      urlOpenStreetMap = urlOpenStreetMap + urlOpenStreetMapPositions;
      urlOsmRouting =
        urlOsmRouting + urlOsmRoutingPositions + urlOsmRoutingSuffix;
      this.urlGoogleMapReal = urlGoogleMap;
      this.urlOpenStreetMapReal = urlOpenStreetMap;
      this.urlOsmRoutingReal = urlOsmRouting;
    },

    //SIMULACIA TRASY z hlavneho stanovista a navratom na stanoviste
    simulateRouteFromStand(waypointList) {
      var map = this.map;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/stand`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          //Najdenie defaultneho stanovista
          itemList.forEach((item) => {
            if (item.defaultStand == true && item.active == true) {
              this.defaultStand = item;
              //Marker na mapu
              var markerPosition = new google.maps.LatLng(
                item.latitude,
                item.longitude
              );
              var defaultStandMarker = new google.maps.Marker({
                position: markerPosition,
                map: map,
                title: "Default Stand",
                icon: {
                  //path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
                  path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
                  fillColor: "#444",
                  fillOpacity: 0.85,
                  strokeColor: "#222",
                  strokeWeight: 1,
                  scale: 1.2,
                  anchor: new google.maps.Point(0, 0),
                  labelOrigin: new google.maps.Point(0, -28),
                },
                label: { text: "S", color: "white" },
              });
            }
          });
          //Google maps URL
          var urlGoogleMap = "https://www.google.com/maps/dir/";
          var urlGoogleMapPositions = "";
          var urlGoogleMapSuffix = "/data=!3m1!4b1!4m2!4m1!3e0";
          //OpenStreetMap URL
          var urlOpenStreetMap =
            "https://www.openstreetmap.org/directions?engine=fossgis_osrm_car&route=";
          var urlOpenStreetMapPositions = "";
          var urlOpenStreetMapSuffix = "";
          //OSM Routing URL
          var urlOsmRouting = "https://routing.taxximo.com/route/v1/driving/";
          var urlOsmRoutingPositions = "";
          var urlOsmRoutingSuffix = "?steps=false";
          //GPS poloha stanovista na zaciatku trasy
          urlGoogleMap =
            urlGoogleMap +
            this.defaultStand.latitude +
            "," +
            this.defaultStand.longitude +
            "/";
          urlOpenStreetMap =
            urlOpenStreetMap +
            this.defaultStand.latitude +
            "," +
            this.defaultStand.longitude +
            ";";
          urlOsmRouting =
            urlOsmRouting +
            this.defaultStand.longitude +
            "," +
            this.defaultStand.latitude +
            ";";
          //GPS poloha stanovista na konci trasy - do suffixu
          urlGoogleMapSuffix =
            "/" +
            this.defaultStand.latitude +
            "," +
            this.defaultStand.longitude +
            urlGoogleMapSuffix;
          urlOpenStreetMapSuffix =
            ";" +
            this.defaultStand.latitude +
            "," +
            this.defaultStand.longitude +
            urlOpenStreetMapSuffix;
          urlOsmRoutingSuffix =
            ";" +
            this.defaultStand.longitude +
            "," +
            this.defaultStand.latitude +
            urlOsmRoutingSuffix;
          //Usporiadanie waypointov podla poradia
          waypointList.sort((a, b) => a.ordering - b.ordering);
          //Najdenie GPS polohy waypointov, ktore su v trase a nie su DISABLED
          waypointList.forEach(function (addressObject) {
            if (addressObject.status != "DISABLED") {
              if (
                addressObject.latitude != null &&
                addressObject.longitude != null
              ) {
                urlGoogleMapPositions =
                  urlGoogleMapPositions +
                  addressObject.latitude +
                  "," +
                  addressObject.longitude +
                  "/";
                urlOpenStreetMapPositions =
                  urlOpenStreetMapPositions +
                  addressObject.latitude +
                  "," +
                  addressObject.longitude +
                  ";";
                urlOsmRoutingPositions =
                  urlOsmRoutingPositions +
                  addressObject.longitude +
                  "," +
                  addressObject.latitude +
                  ";";
              }
            }
          });
          urlGoogleMapPositions = urlGoogleMapPositions.substring(
            0,
            urlGoogleMapPositions.length - 1
          ); // remove last ";"
          urlOpenStreetMapPositions = urlOpenStreetMapPositions.substring(
            0,
            urlOpenStreetMapPositions.length - 1
          ); // remove last ";"
          //console.log("urlOsmRoutingPositions1", urlOsmRoutingPositions);
          urlOsmRoutingPositions = urlOsmRoutingPositions.substring(
            0,
            urlOsmRoutingPositions.length - 1
          ); // remove last ";"
          //console.log("urlOsmRoutingPositions2", urlOsmRoutingPositions);
          urlGoogleMap =
            urlGoogleMap + urlGoogleMapPositions + urlGoogleMapSuffix;
          urlOpenStreetMap =
            urlOpenStreetMap +
            urlOpenStreetMapPositions +
            urlOpenStreetMapSuffix;
          urlOsmRouting =
            urlOsmRouting + urlOsmRoutingPositions + urlOsmRoutingSuffix;
          this.urlGoogleMapWithStand = urlGoogleMap;
          this.urlOpenStreetMapWithStand = urlOpenStreetMap;
          this.urlOsmRoutingWithStand = urlOsmRouting;
          //console.log("urlGoogleMapWithStand", urlGoogleMap);
          //console.log("urlOpenStreetMapWithStand", urlOpenStreetMap);
          //console.log("urlOsmRoutingWithStand", urlOsmRouting);
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },

    setTimeline() {
      var driverList = this.driverList;
      //VYTVORENIE OBJEDNAVKY

      if (this.item.createdAt) {
        this.timelineItems.push({
          id: 1,
          date: this.item.createdAt,
          formatedDate: moment(String(this.item.createdAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderCreate,
          title: this.$t("OrderView.timeline.createdAt_title"),
          text: this.$t("OrderView.timeline.createdAt_text"),
          detail: "",
        });
      }

      //ZMENY STAVOV OBJEDNAVKY

      if (this.item.plannedAt) {
        this.timelineItems.push({
          id: 2,
          date: this.item.plannedAt,
          formatedDate: moment(String(this.item.plannedAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderStatusChange,
          title: this.$t("OrderView.timeline.plannedAt_title"),
          text: this.$t("OrderView.timeline.plannedAt_text"),
          detail: this.item.plannedBy,
        });
      }
      if (this.item.pendingAt) {
        this.timelineItems.push({
          id: 3,
          date: this.item.pendingAt,
          formatedDate: moment(String(this.item.pendingAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderStatusChange,
          title: this.$t("OrderView.timeline.pendingAt_title"),
          text: this.$t("OrderView.timeline.pendingAt_text"),
          detail: this.item.pendingBy,
        });
      }
      if (this.item.acceptedAt) {
        this.timelineItems.push({
          id: 4,
          date: this.item.acceptedAt,
          formatedDate: moment(String(this.item.acceptedAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderStatusChange,
          title: this.$t("OrderView.timeline.acceptedAt_title"),
          text: this.$t("OrderView.timeline.acceptedAt_text"),
          detail: this.item.acceptedBy,
        });
      }
      if (this.item.waitingAt) {
        this.timelineItems.push({
          id: 5,
          date: this.item.waitingAt,
          formatedDate: moment(String(this.item.waitingAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderStatusChange,
          title: this.$t("OrderView.timeline.waitingAt_title"),
          text: this.$t("OrderView.timeline.waitingAt_text"),
          detail: this.item.waitingBy,
        });
      }
      if (this.item.progressAt) {
        this.timelineItems.push({
          id: 6,
          date: this.item.progressAt,
          formatedDate: moment(String(this.item.progressAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderStatusChange,
          title: this.$t("OrderView.timeline.progressAt_title"),
          text: this.$t("OrderView.timeline.progressAt_text"),
          detail: this.item.progressBy,
        });
      }
      if (this.item.finishedAt) {
        this.timelineItems.push({
          id: 7,
          date: this.item.finishedAt,
          formatedDate: moment(String(this.item.finishedAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderStatusChange,
          title: this.$t("OrderView.timeline.finishedAt_title"),
          text: this.$t("OrderView.timeline.finishedAt_text"),
          detail: this.item.finishedBy,
        });
      }
      if (this.item.chargedAt) {
        this.timelineItems.push({
          id: 8,
          date: this.item.chargedAt,
          formatedDate: moment(String(this.item.chargedAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderStatusChange,
          title: this.$t("OrderView.timeline.chargedAt_title"),
          text: this.$t("OrderView.timeline.chargedAt_text"),
          detail: this.item.chargedBy,
        });
      }
      if (this.item.settledAt) {
        this.timelineItems.push({
          id: 9,
          date: this.item.settledAt,
          formatedDate: moment(String(this.item.settledAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderStatusChange,
          title: this.$t("OrderView.timeline.settledAt_title"),
          text: this.$t("OrderView.timeline.settledAt_text"),
          detail: this.item.settledBy,
        });
      }
      if (this.item.interruptedAt) {
        this.timelineItems.push({
          id: 10,
          date: this.item.interruptedAt,
          formatedDate: moment(String(this.item.interruptedAt)).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          type: TYPE.orderCancel,
          title: this.$t("OrderView.timeline.interruptedAt_title"),
          text: this.$t("OrderView.timeline.interruptedAt_text"),
          detail: this.item.interruptedBy,
        });
      }

      //POZADOVANY CAS VYZDVIHNUTIA

      if (this.item.requestedTime) {
        //Potrebujeme iba podla datumu requestedTime zistit, ci je objednavka casovka alebo nie
        //Problem je, ze sa v pripade okamzitej jazdy nezhoduje createdAt a requestedTime. Tak tam dame toleranciu na 1 minutu
        if (this.item.createdAt) {
          var t1 = new Date(this.item.createdAt);
          var t2 = new Date(this.item.requestedTime);
          var difference = (t2.getTime() - t1.getTime()) / 1000;
          //console.log("difference: " + difference);
          if (
            this.item.type == "INSTANT" ||
            (difference > -60 && difference < 60)
          ) {
            this.timelineItems.push({
              id: 11,
              date: this.item.requestedTime,
              formatedDate: moment(String(this.item.requestedTime)).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
              type: TYPE.requestedTime,
              title: this.$t("OrderView.timeline.requestedTimeInstant_title"),
              text: this.$t("OrderView.timeline.requestedTimeInstant_text"),
              detail: "",
            });
          } else if (this.item.type == "TIME") {
            this.timelineItems.push({
              id: 11,
              date: this.item.requestedTime,
              formatedDate: moment(String(this.item.requestedTime)).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
              type: TYPE.requestedTime,
              title: this.$t("OrderView.timeline.orderSentLogsTime_title"),
              text: this.$t("OrderView.timeline.orderSentLogsTime_text"),
              detail: "",
            });
          }
        }
      }

      //CHAT

      if (this.item.chatList) {
        //translation
        var chatList_title = this.$t("OrderView.timeline.chatList_title");

        var chatList = this.item.chatList;
        var chatNumber = 100; //ID pre chat musi byt unikatne, preto ho nastavujeme seriu na 100
        var chatTimelineArray = [];
        var displayOnlyForAdmin = this.displayOnlyForAdmin;
        chatList.forEach(function (chat) {
          if (chat.createdAt) {
            chatNumber++;
            chatTimelineArray.push({
              id: chatNumber,
              date: chat.createdAt,
              formatedDate: moment(String(chat.createdAt)).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
              type: TYPE.chat,
              title: chatList_title,
              text: chat.message,
              detail:
                chat.fromType +
                " " +
                (chat.from ? chat.from.name : "") +
                " " +
                (chat.from && displayOnlyForAdmin == true ? chat.from.id : ""),
            });
          }
        });
        if (chatTimelineArray.length > 0) {
          this.timelineItems = this.timelineItems.concat(chatTimelineArray);
        }
      }

      //WAYPOINT

      if (this.item.waypointList) {
        //translation
        var waypointIssue_title = this.$t(
          "OrderView.timeline.waypointIssue_title"
        );
        var waypointIssue1_text = this.$t(
          "OrderView.timeline.waypointIssue1_text"
        );
        var waypointIssue2_text = this.$t(
          "OrderView.timeline.waypointIssue2_text"
        );
        var waypointIssue_detail = this.$t(
          "OrderView.timeline.waypointIssue_detail"
        );
        var waypointListBoard_title = this.$t(
          "OrderView.timeline.waypointListBoard_title"
        );
        var waypointListDestination_title = this.$t(
          "OrderView.timeline.waypointListDestination_title"
        );
        var waypointListTransit_title = this.$t(
          "OrderView.timeline.waypointListTransit_title"
        );

        var item = this.item;
        var waypointList = this.item.waypointList;
        var waypointNumber = 200; //ID pre chat musi byt unikatne, preto ho nastavujeme seriu na 200
        var waypointTimelineArray = [];
        var displayOnlyForAdmin = this.displayOnlyForAdmin;
        waypointList.forEach(function (waypoint) {
          if (waypoint.completedAt != null && waypoint.status == "COMPLETED") {
            //Zobrazujeme len kompletne waypointy
            waypointNumber++;
            //compare with requestedTime and completedAt
            //console.log("requestedTime: " + item.requestedTime);
            //console.log("completedAt: " + waypoint.completedAt);
            //console.log("waypoint.type", waypoint.type);
            if (
              item.type == "TIME" &&
              item.requestedTime &&
              waypoint.type == "BOARD" &&
              waypoint.completedAt
            ) {
              var t1 = new Date(waypoint.completedAt);
              var t2 = new Date(item.requestedTime);
              var difference = (t2 - t1) / 1000;
              console.log("difference TIME vs requestedTime: " + difference);
              //pridat 2 sekundy do waypoint.completedAt tohto zaznamu aby sa zobrazoval zaznam o meskani na timeline az po zobrazenie requestedTime
              var t3 = new Date(waypoint.completedAt);
              t3.setSeconds(t3.getSeconds() + 2);
              var t3 = t3.toISOString();
              //Tolerancia 60 sekund pre neskorsi prichod auta do miesta vyzdvihnutia
              if (difference < -60) {
                waypointTimelineArray.push({
                  id: waypointNumber + 20,
                  date: t3,
                  formatedDate: moment(String(waypoint.completedAt)).format(
                    "DD.MM.YYYY HH:mm:ss"
                  ),
                  type: TYPE.waypointIssue,
                  title: waypointIssue_title,
                  text:
                    waypointIssue1_text +
                    Math.round(Math.abs(-difference / 60)) +
                    waypointIssue2_text,
                  detail: item.requestedTime
                    ? waypointIssue_detail +
                      moment(String(item.requestedTime)).format(
                        "DD.MM.YYYY HH:mm:ss"
                      )
                    : "",
                });
              }
            }
            //Zaznamenanie prejadzoveho bodu do timeline
            waypointTimelineArray.push({
              id: waypointNumber,
              date: waypoint.completedAt,
              formatedDate: moment(String(waypoint.completedAt)).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
              type: TYPE.waypoint,
              title:
                (waypoint.type == "BOARD"
                  ? waypointListBoard_title
                  : waypoint.type == "DESTINATION"
                  ? waypointListDestination_title
                  : waypointListTransit_title) +
                (waypoint.address ? ": " + waypoint.address : ""),
              text:
                (waypoint.status ? waypoint.status : "") +
                " " +
                (waypoint.type ? waypoint.type : "") +
                " (" +
                (waypoint.ordering ? waypoint.ordering : "") +
                ") " +
                (waypoint.latitude ? waypoint.latitude : "") +
                ", " +
                (waypoint.longitude ? waypoint.longitude : ""),
              detail:
                (waypoint.id && displayOnlyForAdmin == true
                  ? waypoint.id
                  : "") +
                (waypoint.localityId && displayOnlyForAdmin == true
                  ? waypoint.localityId
                  : ""),
            });
          }
        });
        if (waypointTimelineArray.length > 0) {
          this.timelineItems = this.timelineItems.concat(waypointTimelineArray);
        }
      }

      //WAITING

      if (this.item.waitingList) {
        //translation
        var waitingListStartedAt_title = this.$t(
          "OrderView.timeline.waitingListStartedAt_title"
        );
        var waitingListStartedAt_text = this.$t(
          "OrderView.timeline.waitingListStartedAt_text"
        );
        var waitingListFinishedAt_title = this.$t(
          "OrderView.timeline.waitingListFinishedAt_title"
        );
        var waitingListFinishedAt1_text = this.$t(
          "OrderView.timeline.waitingListFinishedAt1_text"
        );
        var waitingListFinishedAt2_text = this.$t(
          "OrderView.timeline.waitingListFinishedAt2_text"
        );

        var waitingList = this.item.waitingList;
        var waitingNumber = 300; //ID pre chat musi byt unikatne, preto ho nastavujeme seriu na 300
        var waitingTimelineArray = [];
        var displayOnlyForAdmin = this.displayOnlyForAdmin;
        waitingList.forEach(function (waiting) {
          //Z jedneho riadku vytvorime dva timeline items, pre zaciatok a koniec cakacky
          if (waiting.startedAt) {
            waitingNumber++;
            waitingTimelineArray.push({
              id: waitingNumber,
              date: waiting.startedAt,
              formatedDate: moment(String(waiting.startedAt)).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
              type: TYPE.waiting,
              title: waitingListStartedAt_title,
              text: waitingListStartedAt_text,
              detail: "",
            });
          }
          if (waiting.finishedAt && waiting.status == "COMPLETED") {
            //Time in minutes (waiting.time) rounded up to nearest 1 minute
            var time_minutes = Math.ceil(waiting.time / 60);
            //Kontrola, ci su minuty na serveri spravne zaokruhlene
            if (waiting.timeMin) {
              if (time_minutes != waiting.timeMin) {
                console.log(
                  "WAITING TIME MISMATCH - minuty na serveri nie su dobre zaokruhlené"
                );
              }
            }

            waitingNumber++;
            waitingTimelineArray.push({
              id: waitingNumber,
              date: waiting.finishedAt,
              formatedDate: moment(String(waiting.finishedAt)).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
              type: TYPE.waiting,
              title: waitingListFinishedAt_title,
              text:
                waitingListFinishedAt1_text +
                (waiting.time
                  ? time_minutes +
                    waitingListFinishedAt2_text +
                    waiting.time +
                    " sec.)"
                  : " ? min."),
              detail: waiting.status + " ",
            });
          }
        });
        if (waitingTimelineArray.length > 0) {
          this.timelineItems = this.timelineItems.concat(waitingTimelineArray);
        }
      }

      //ORDER LOGS

      if (this.item.orderLogList) {
        //translation
        var orderLogList_title = this.$t(
          "OrderView.timeline.orderLogList_title"
        );

        var orderLogList = this.item.orderLogList;
        var orderLogNumber = 400; //ID pre chat musi byt unikatne, preto ho nastavujeme seriu na 100
        var orderLogTimelineArray = [];
        var displayOnlyForAdmin = this.displayOnlyForAdmin;
        orderLogList.forEach(function (orderLog) {
          if (orderLog.createdAt) {
            orderLogNumber++;
            orderLogTimelineArray.push({
              id: orderLogNumber,
              date: orderLog.createdAt,
              formatedDate: moment(String(orderLog.createdAt)).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
              type: TYPE.orderLog,
              title: orderLogList_title,
              text: orderLog.message,
              detail:
                orderLog.type +
                " " +
                /*(orderLog.message ? orderLog.message : "") +
                " " +*/
                (orderLog.changedBy && displayOnlyForAdmin == true
                  ? orderLog.changedBy
                  : ""),
            });
          }
        });
        if (orderLogTimelineArray.length > 0) {
          this.timelineItems = this.timelineItems.concat(orderLogTimelineArray);
        }
      }

      //ORDER SEND LOGS
      if (this.item.orderSentLogs) {
        //translation
        var orderSentLogs_title = this.$t(
          "OrderView.timeline.orderSentLogs_title"
        );
        var orderSentLogs1_text = this.$t(
          "OrderView.timeline.orderSentLogs1_text"
        );
        var orderSentLogs2_text = this.$t(
          "OrderView.timeline.orderSentLogs2_text"
        );
        var orderSentLogs3_text = this.$t(
          "OrderView.timeline.orderSentLogs3_text"
        );

        var orderSentLogs = this.item.orderSentLogs;
        var orderSentLogsNumber = 500; //ID pre chat musi byt unikatne, preto ho nastavujeme seriu na 100
        var orderSentLogsTimelineArray = [];
        var displayOnlyForAdmin = this.displayOnlyForAdmin;

        orderSentLogs.forEach(function (orderSentLog) {
          if (orderSentLog.createdAt) {
            //select firstName, lastName from driverList where id = orderSentLog.driverId
            var driverFirstName = "";
            var driverLastName = "";
            driverList.forEach(function (driver) {
              if (driver.id == orderSentLog.driverId) {
                driverFirstName = driver.firstName;
                driverLastName = driver.lastName;
              }
            });
            orderSentLogsNumber++;
            orderSentLogsTimelineArray.push({
              id: orderSentLogsNumber,
              date: orderSentLog.createdAt,
              formatedDate: moment(String(orderSentLog.createdAt)).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
              type: TYPE.orderSentLog,
              title: orderSentLogs_title,
              text:
                orderSentLogs1_text +
                orderSentLog.fromBy +
                orderSentLogs2_text +
                driverFirstName +
                " " +
                driverLastName +
                " (" +
                orderSentLog.driverId +
                ")" +
                orderSentLogs3_text +
                orderSentLog.distance +
                "m " +
                (orderSentLog.message && displayOnlyForAdmin == true
                  ? " | Message: " + orderSentLog.message
                  : ""),
              detail: orderSentLog.fromBy,
            });
          }
        });
        /*
              title: this.$t("OrderView.timeline.orderSentLogs_title"),
              text:
                this.$t("OrderView.timeline.orderSentLogs1_text") +
                orderSentLogs.fromBy +
                this.$t("OrderView.timeline.orderSentLogs2_text") +
                orderSentLog.driverId +
                this.$t("OrderView.timeline.orderSentLogs3_text") +
                orderSentLog.distance +
                "m " +
        */
        if (orderSentLogsTimelineArray.length > 0) {
          this.timelineItems = this.timelineItems.concat(
            orderSentLogsTimelineArray
          );
        }
      }

      //Usporiadanie podla casu od najskorsieho zaznamu po posledny
      var timelineItemsSorted = this.timelineItems.sort((a, b) =>
        a.date > b.date ? 1 : b.date > a.date ? -1 : 0
      );
      this.timelineItems = timelineItemsSorted;
    },

    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },

    fitBoundsMap() {
      //spomalenie kvôli renderovania a vyzera to tak lepsie
      var map = this.map;
      var bounds = this.mapBounds;
      setTimeout(function () {
        map.fitBounds(bounds);
      }, 1000);
      var centerOfMap;
      setTimeout(() => {
        centerOfMap = map.getCenter();
        //zapamatat do cookies, aby sa pri preklikavani medzi objednavkami nenacitavali zbytocne mapove podklady
        this.setCookie("centerOfMap", JSON.stringify(centerOfMap), 30);
      }, 2000);
      var zoomOfMap;
      setTimeout(() => {
        zoomOfMap = map.getZoom();
        //zapamatat do cookies, aby sa pri preklikavani medzi objednavkami nenacitavali zbytocne mapove podklady
        //dat zoom o 2 nizsie, aby sa mohol urobit efekt priblizenia
        this.setCookie("zoomOfMap", zoomOfMap - 2, 30);
      }, 2000);
    },

    getItemsLocalityPrice() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-price`)
        .then((response) => {
          var priceLocalityList = response.data;
          //display only active items
          if (priceLocalityList) {
            this.priceLocalityList = priceLocalityList.filter(
              (item) => item.active == true
            );
            this.displayShapesOnMap(this.priceLocalityList);
            this.addLocalityNameToOrder();
          }
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },

    //Pridat k ID lokality (estimatedLocFrom) aj názov
    addLocalityNameToOrder() {
      if (this.displayOnlyForAdmin) {
        this.item.estimatedLocFrom = this.addLocalityNameToId(
          this.item.estimatedLocFrom
        );
        this.item.estimatedLocTo = this.addLocalityNameToId(
          this.item.estimatedLocTo
        );
        this.item.finalLocFrom = this.addLocalityNameToId(
          this.item.finalLocFrom
        );
        this.item.finalLocTo = this.addLocalityNameToId(this.item.finalLocTo);
        this.item.infoLocFrom = this.addLocalityNameToId(this.item.infoLocFrom);
        this.item.infoLocTo = this.addLocalityNameToId(this.item.infoLocTo);
      }
    },

    addLocalityNameToId(localityId) {
      var localityName = "";
      if (localityId) {
        var locality = this.priceLocalityList.find(
          (item) => item.id == localityId
        );
        if (locality) {
          localityName = localityId + " - " + locality.name;
        }
      }
      return localityName;
    },

    displayShapesOnMap(itemList) {
      var map = this.map;
      var localityShapes = this.localityShapes;
      //var itemList = this.gridItemsLocality;
      var colorName = eval(0); //Reset poradia farby
      //Premazanie starých markerov
      for (var i = 0; i < localityShapes.length; i++) {
        localityShapes[i].setMap(null);
      }
      //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
      if (itemList.constructor.name != "Array") {
        var newItemList = [];
        newItemList.push(itemList);
        itemList = newItemList;
      }
      //Vytvorenie nových markerov
      for (var city in itemList) {
        colorName = colorName + eval(1);
        //nadefinovanie, aby bola vysoká priesvitnosť objektu s pioritou -1000
        var fillOpacityIndex = 0.12;
        var strokeColorIndex = "#795548";
        if (itemList[city].priority == "-1000") {
          fillOpacityIndex = 0.04;
        } else if (itemList[city].priority == "-999") {
          fillOpacityIndex = 0.06;
        } else {
          fillOpacityIndex = 0.08;
        }
        //Ak sa zobrazuju stanovistia, tak hlavne z nich zvyraznit cervenym obrysom
        if (itemList[city].defaultStand) {
          if (itemList[city].defaultStand == true) {
            strokeColorIndex = "#F00000";
          } else {
            strokeColorIndex = "#FFFF00";
          }
        }
        //console.log("city", city);
        var latitude;
        var longitude;
        var radius;
        if (itemList[city].type == "POLYGON") {
          var prePath = [];
          var postPath = [];
          var finalPath = [];
          prePath = JSON.parse(itemList[city].path);

          //console.log("Polygon pre " + prePath);
          //console.log(JSON.parse(itemList[city].id));
          for (var i = 0; i < prePath.length; i++) {
            postPath.push({
              lat: prePath[i][0],
              lng: prePath[i][1],
            });
            //bounds.extend(new google.maps.LatLng(prePath[i][0], prePath[i][1]));
          }
          //var postPath = jsonData["locality_info"]["path"];
          var _len = postPath.length;

          //console.log("Polygon post" + postPath);
          //var postPath = "[";
          var i;
          //var finalPath;
          for (i = 0; i < _len; i++) {
            finalPath.push(postPath[i]);
          }

          //console.log("Polygon final" + finalPath);

          var addListenersOnPolygon = function (polygon) {
            google.maps.event.addListener(polygon, "click", function (event) {
              alert(polygon.title);
            });
          };
          //			function createPolygon() {

          var locationPolygon = new google.maps.Polygon({
            paths: finalPath,
            strokeColor: strokeColorIndex,
            strokeOpacity: 0.4,
            strokeWeight: 1,
            fillColor: this.rangeColor(colorName),
            fillOpacity: fillOpacityIndex,
            editable: false,
            draggable: false,
            title:
              "ID: " +
              itemList[city].id +
              " | Name: " +
              itemList[city].name +
              " | Priority: " +
              itemList[city].priority +
              " | Type: Polygon",
            zIndex: itemList[city].priority,
            idx: itemList[city].id,
            label: "B",
          });
          localityShapes.push(locationPolygon);
          locationPolygon.setMap(map);
          addListenersOnPolygon(locationPolygon);

          //				  }
          // Create Polygon
          //				setTimeout(createPolygon, 3000);
        } else {
          //console.log("city", city);
          var addListenersOnCircle = (circle) => {
            google.maps.event.addListener(circle, "click", function (event) {
              alert(circle.title);
            });
          };

          latitude = itemList[city].latitude;
          longitude = itemList[city].longitude;
          radius = itemList[city].radius;
          //console.log("latitude",latitude,"longitude",longitude,"radius",radius);
          /*tempGeo = new google.maps.LatLng(
            parseFloat(latitude) + 11,
            parseFloat(longitude) + 21
          );
          console.log("tempGeo1", tempGeo);*/
          var cityCircle = new google.maps.Circle({
            editable: false,
            strokeColor: strokeColorIndex,
            strokeOpacity: 0.4,
            strokeWeight: 2,
            //fillColor: '#FF'+(Math.round(city)+eval(10))+'00',
            fillColor: this.rangeColor(colorName),
            fillOpacity: fillOpacityIndex,
            zIndex: itemList[city].priority,
            map: map,
            //{"6":{"id":"6","name":"Trnava","lat":"48.37741","lng":"17.58723","radius":"5000","TAXISERVICES_id":"5"},"9":{"id":"9","name":"Tren\u010d\u00edn","lat":"48.888248","lng":"18.019179","radius":"10000","TAXISERVICES_id":"5"}}
            center: new google.maps.LatLng(latitude, longitude),
            radius: parseInt(radius),
            title:
              "ID: " +
              itemList[city].id +
              " | Name: " +
              itemList[city].name +
              " | Priority: " +
              itemList[city].priority +
              " | Type: Circle",
            idx: itemList[city].id,
            label: "A",
          });
          //map.setCenter( new google.maps.LatLng(itemList[city].lat, itemList[city].lng));
          //map.setZoom(7);  // Why 16? Because it looks good.
          //console.log("cityCircle", cityCircle);
          localityShapes.push(cityCircle);
          cityCircle.setMap(map);
          addListenersOnCircle(cityCircle);
        }
      }
      this.localityShapes = localityShapes;
      //console.log("localityShapes", localityShapes);
    },

    rangeColor(colorName) {
      switch (colorName) {
        case 1:
          return "#00FF00";
          break; //green
        case 2:
          return "#FFD700";
          break; //gold
        case 3:
          return "#996633";
          break; //
        case 4:
          return "#FF8C00";
          break; //
        case 5:
          return "#EE82EE";
          break; //violet
        case 6:
          return "#0000FF";
          break; //blue
        case 7:
          return "#00008B";
          break; //
        case 8:
          return "#ADD8E6";
          break; //
        case 9:
          return "#87CEEB";
          break; //
        case 10:
          return "#FF0000";
          break; //red
        case 11:
          return "#ffa500";
          break; //orange
        case 12:
          return "#800080";
          break; //purple
        case 13:
          return "#FFFF00";
          break; //yellow
        case 14:
          return "#C0C0C0";
          break; //silver
        case 15:
          return "#006400";
          break; //
        case 16:
          return "#90EE90";
          break; //
        case 17:
          return "#FF99CC";
          break; //
        case 18:
          return "#F5F5DC";
          break; //beige
        case 19:
          return "#696969";
          break; //
        case 20:
          return "#E52B50";
          break; //amaranth
        case 21:
          return "#FFBF00";
          break; //amber
        case 22:
          return "#007FFF";
          break; //azure
        case 23:
          return "#CD7F32";
          break; //bronze
        case 24:
          return "#DE3163";
          break; //cerise
        case 25:
          return "#7B3F00";
          break; //chocolate
        case 26:
          return "#0047AB";
          break; //cobalt blue
        case 27:
          return "#F88379";
          break; //coral
        case 28:
          return "#50C878";
          break; //emerald
        case 29:
          return "#4B0082";
          break; //indigo
        case 30:
          return "#00A86B";
          break; //jade
        case 31:
          return "#B57EDC";
          break; //lavender
        case 32:
          return "#BFFF00";
          break; //lime
        case 33:
          return "#FF00FF";
          break; //magenta
        case 34:
          return "#800000";
          break; //maroon
        case 35:
          return "#000080";
          break; //navy blue
        case 36:
          return "#FF2400";
          break; //scarlet
        case 37:
          return "#A7FC00";
          break; //spring bud
        case 38:
          return "#008080";
          break; //teal
        case 39:
          return "#FFC0CB";
          break; //pink
        case 40:
          return "#D1E231";
          break; //pear
        case 41:
          return "#00FF10";
          break; //green
        case 42:
          return "#FFD710";
          break; //gold
        case 43:
          return "#996643";
          break; //
        case 44:
          return "#FF8C10";
          break; //
        case 45:
          return "#EE82FE";
          break; //violet
        case 46:
          return "#0000EF";
          break; //blue
        case 47:
          return "#00009B";
          break; //
        case 48:
          return "#ADD8F6";
          break; //
        case 49:
          return "#87CEFB";
          break; //
        case 50:
          return "#FF0010";
          break; //red
        case 51:
          return "#ffa510";
          break; //orange
        case 52:
          return "#800090";
          break; //purple
        case 53:
          return "#FFFF10";
          break; //yellow
        case 54:
          return "#C0C0D0";
          break; //silver
        case 55:
          return "#006410";
          break; //
        case 56:
          return "#90EE80";
          break; //
        case 57:
          return "#FF99DC";
          break; //
        case 58:
          return "#F5F5EC";
          break; //beige
        case 59:
          return "#696979";
          break; //
        case 60:
          return "#E52B60";
          break; //amaranth
        case 61:
          return "#FFBF10";
          break; //amber
        case 62:
          return "#007FEF";
          break; //azure
        case 63:
          return "#CD7F42";
          break; //bronze
        case 64:
          return "#DE3173";
          break; //cerise
        case 65:
          return "#7B3F10";
          break; //chocolate
        case 66:
          return "#0047BB";
          break; //cobalt blue
        case 67:
          return "#F88389";
          break; //coral
        case 68:
          return "#50C878";
          break; //emerald
        case 69:
          return "#4B0082";
          break; //indigo
        case 70:
          return "#00A86B";
          break; //jade
        case 71:
          return "#B57EDC";
          break; //lavender
        case 72:
          return "#BFFF00";
          break; //lime
        case 73:
          return "#FF00FF";
          break; //magenta
        case 74:
          return "#800000";
          break; //maroon
        case 75:
          return "#000080";
          break; //navy blue
        case 76:
          return "#FF2400";
          break; //scarlet
        case 77:
          return "#A7FC00";
          break; //spring bud
        case 78:
          return "#008080";
          break; //teal
        case 79:
          return "#FFC0CB";
          break; //pink
        case 80:
          return "#D1E231";
          break; //pear
        default:
          return "#FF6600";
      }
    },
  },
};
</script>
