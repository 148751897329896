<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("ReportOverview.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-chart-pie</v-icon>
    </v-toolbar>

    <v-container fluid>
      <v-data-iterator
        :items="itemsPeriod"
        :items-per-page.sync="itemsPeriodPerPage"
        hide-default-footer
      >
        <!-- <template v-slot:header>
          <v-toolbar class="mb-2" color="indigo darken-5" dark flat>
            <v-toolbar-title>This is a header</v-toolbar-title>
          </v-toolbar>
        </template>-->

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.periodName"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="subheading font-weight-bold">
                  {{ item.periodName }}
                </v-card-title>

                <v-divider></v-divider>

                <!--<v-list dense>
                  <v-list-item>
                    <v-list-item-content>Zrealizované:</v-list-item-content>
                    <v-list-item-content class="align-end d-flex justify-end">
                      {{ item.allRealized }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content
                      >Zrušené počas realizácie:</v-list-item-content
                    >
                    <v-list-item-content class="align-end d-flex justify-end">
                      {{ item.allCanceledRealized }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content
                      >Zrušené pred realizáciou:</v-list-item-content
                    >
                    <v-list-item-content class="align-end d-flex justify-end">
                      {{ item.allCanceledUnrealized }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>-->

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="9">
                          <v-row no-gutters justify="start">{{
                            $t("ReportOverview.allRealized")
                          }}</v-row>
                        </v-col>

                        <v-col cols="3">
                          <v-row
                            no-gutters
                            class="align-end d-flex justify-end"
                            >{{ item.allRealized }}</v-row
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content
                      ><v-row>
                        <v-col cols="9">
                          <v-row no-gutters justify="start">{{
                            $t("ReportOverview.allCanceledRealized")
                          }}</v-row>
                        </v-col>

                        <v-col cols="3">
                          <v-row
                            no-gutters
                            class="align-end d-flex justify-end"
                            >{{ item.allCanceledRealized }}</v-row
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content
                      ><v-row>
                        <v-col cols="9">
                          <v-row no-gutters justify="start">{{
                            $t("ReportOverview.allCanceledUnrealized")
                          }}</v-row>
                        </v-col>

                        <v-col cols="3">
                          <v-row
                            no-gutters
                            class="align-end d-flex justify-end"
                            >{{ item.allCanceledUnrealized }}</v-row
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
    <v-spacer></v-spacer>
    <br />

    <BarChart :chartData="chartData" :height="chartHeight" />
    <v-spacer></v-spacer>
    <br />
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{
        $t("ReportOverview.sourceStatistic")
      }}</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>
      <v-data-iterator
        :items="itemsSource"
        :items-per-page.sync="itemsSourcePerPage"
        hide-default-footer
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.sourceName"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="subheading font-weight-bold">
                  {{ item.sourceName }}
                </v-card-title>

                <v-divider></v-divider>

                <!-- <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Zrealizované:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.allRealized }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content
                      >Zrušené počas realizácie:</v-list-item-content
                    >
                    <v-list-item-content class="align-end">
                      {{ item.allCanceledRealized }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content
                      >Zrušené pred realizáciou:</v-list-item-content
                    >
                    <v-list-item-content class="align-end">
                      {{ item.allCanceledUnrealized }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>-->
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="9">
                          <v-row no-gutters justify="start">{{
                            $t("ReportOverview.allRealized")
                          }}</v-row>
                        </v-col>

                        <v-col cols="3">
                          <v-row
                            no-gutters
                            class="align-end d-flex justify-end"
                            >{{ item.allRealized }}</v-row
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content
                      ><v-row>
                        <v-col cols="9">
                          <v-row no-gutters justify="start">{{
                            $t("ReportOverview.allCanceledRealized")
                          }}</v-row>
                        </v-col>

                        <v-col cols="3">
                          <v-row
                            no-gutters
                            class="align-end d-flex justify-end"
                            >{{ item.allCanceledRealized }}</v-row
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content
                      ><v-row>
                        <v-col cols="9">
                          <v-row no-gutters justify="start">{{
                            $t("ReportOverview.allCanceledUnrealized")
                          }}</v-row>
                        </v-col>

                        <v-col cols="3">
                          <v-row
                            no-gutters
                            class="align-end d-flex justify-end"
                            >{{ item.allCanceledUnrealized }}</v-row
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
    <v-spacer></v-spacer>
    <br />
    <PieChart :chartData="chartPieData" :height="chartPieHeight" />
  </div>
</template>

<script>
import axios from "axios";
import BarChart from "../components/BarChart";
import PieChart from "../components/PieChart";

export default {
  name: "ReportOverview",
  components: { BarChart, PieChart },
  data() {
    return {
      dateRange: [], //["2021-07-22","2021-07-24"],

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      itemsPeriodPerPage: 4,
      itemsPeriod: [
        {
          periodName: "",
          allRealized: 0,
          allCanceledRealized: 0,
          allCanceledUnrealized: 0,
        },
      ],
      itemsSourcePerPage: 4,
      itemsSource: [
        {
          sourceName: "",
          allRealized: 0,
          allCanceledRealized: 0,
          allCanceledUnrealized: 0,
        },
      ],
      chartData: {
        labels: ["-", "-"],
        datasets: [
          {
            label: "-",
            backgroundColor: "#f87979",
            data: [0, 0],
          },
        ],
      },
      chartHeight: 100,

      chartPieData: {
        labels: ["-", "-"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: [0, 0],
          },
        ],
      },
      chartPieHeight: 240,
    };
  },
  created() {},

  mounted() {
    //default prvy den minuleho mesiaca a posledny den aktualneho mesiaca
    var currentTime = new Date();
    //currentTime.setDate(1);
    //console.log("currentTime", currentTime);
    var dayF = new Date();
    var dayT = new Date();
    dayF.setMonth(dayF.getMonth() - 1);
    dayF.setDate(1);
    dayF.setHours(0, 0, 0, 0);
    dayT.setDate(1);
    dayT.setMonth(dayT.getMonth() + 1);
    dayT.setDate(dayT.getDate() - 1);
    dayT.setHours(23, 59, 59, 999);
    this.dateRange = [
      new Date(dayF).toISOString(),
      new Date(dayT).toISOString(),
    ];
    /*this.dateRange = [
      new Date(currentTime.getFullYear(), currentTime.getMonth() - 3, 2)
        .toISOString()
        .substr(0, 10),
      new Date(currentTime.getFullYear(), currentTime.getMonth() + 1, 0)
        .toISOString()
        .substr(0, 10),
    ];*/
    //console.log("dateRange", this.dateRange);
    this.getReportData();
  },
  methods: {
    getReportData() {
      //console.log("report dateRange:  ", this.dateRange);
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/statistics?from=${this.dateRange[0]}&to=${this.dateRange[1]}`
        )
        .then((response) => {
          //console.log("response", response);
          this.loadingData = false;
          var tempResponseData = response.data;
          var tempAllSummary = [];
          var tempSourceSumary = [];
          var allRealizedCounter = 0;
          var allCanceledRealizedCounter = 0;
          var allCanceledUnrealizedCounter = 0;
          var tempAll = {};
          var tempAllDefault = {
            periodName: "",
            allRealized: 0,
            allCanceledRealized: 0,
            allCanceledUnealized: 0,
          };
          var tempSource = {};
          var tempSourceDefault = {
            sourceName: "",
            allRealized: 0,
            allCanceledRealized: 0,
            allCanceledUnealized: 0,
          };
          //console.log("tempResponseData 1:", tempResponseData);
          //console.log("tempModifiedData", tempModifiedData);
          var ordered;
          /*ordered = this.sortObjectAsc(tempResponseData);
         //console.log("ordered Asc:", ordered);*/
          if (tempResponseData) {
            ordered = this.sortObjectDesc(tempResponseData);
            //console.log("ordered Desc:", ordered);
            tempResponseData = Object.assign({}, ordered);
            //console.log("tempResponseData 2:", tempResponseData);
            //SUCTY ZA OBDOBIA
            Object.keys(tempResponseData).forEach((key) => {
              //vynulovat hodnoty
              tempAll = Object.assign({}, tempAllDefault);
              allRealizedCounter = 0;
              allCanceledRealizedCounter = 0;
              allCanceledUnrealizedCounter = 0;
              //aktualna Item - mesiac
              var monthItem = tempResponseData[key];
              //console.log(key, tempResponseData[key]);
              //Doplnit nazov tabulky
              tempAll.periodName = key;
              //console.log("tempAll:", tempAll);
              Object.keys(monthItem).forEach((key) => {
                //aktualna Item - zdroj
                var sourceItem = monthItem[key];
                //console.log(key, monthItem[key]);
                Object.keys(sourceItem).forEach((key) => {
                  //console.log(key, sourceItem[key]);
                  if (key == "SETTLED") {
                    allRealizedCounter = allRealizedCounter + sourceItem[key];
                  } else if (
                    key == "CANCELLED_ACCEPTED" ||
                    key == "CANCELLED_CHARGED" ||
                    key == "CANCELLED_FINISHED" ||
                    key == "CANCELLED_IN_PROGRESS" ||
                    key == "CANCELLED_SETTLED" ||
                    key == "CANCELLED_WAITING"
                  ) {
                    allCanceledRealizedCounter =
                      allCanceledRealizedCounter + sourceItem[key];
                  } else if (
                    key == "CANCELLED_NEW" ||
                    key == "CANCELLED_PENDING" ||
                    key == "CANCELLED_PLANNED" ||
                    key == "CANCELLED_PREREGISTERED"
                  ) {
                    allCanceledUnrealizedCounter =
                      allCanceledUnrealizedCounter + sourceItem[key];
                  }
                });
                tempAll.allRealized = allRealizedCounter;
                tempAll.allCanceledRealized = allCanceledRealizedCounter;
                tempAll.allCanceledUnrealized = allCanceledUnrealizedCounter;
              });
              tempAllSummary.push(tempAll);
              //console.log("tempAllSummary 3:", tempAllSummary);
            });
            //console.log("tempAllSummary 4", tempAllSummary);
            this.itemsPeriod = tempAllSummary;
            //console.log("this.itemsPeriod", this.itemsPeriod);
            //Uprava dat pre graf
            var graphData = {};
            var graphDataValues = {};
            var graphLabels = [];
            var graphDatasetDataAllRealized = [];
            var graphDatasetDataAllCanceledRealized = [];
            var graphDatasetDataAllCanceledUnrealized = [];
            tempAllSummary.forEach((element) => {
              //console.log("element.periodName", element.periodName);
              graphLabels.push(element.periodName);
              graphDatasetDataAllRealized.push(element.allRealized);
              graphDatasetDataAllCanceledRealized.push(
                element.allCanceledRealized
              );
              graphDatasetDataAllCanceledUnrealized.push(
                element.allCanceledUnrealized
              );
            });
            //labels: [],
            graphData.labels = graphLabels;
            //datasets: [{ data: [] }],
            graphData.datasets = [];
            graphDataValues = {};
            graphDataValues.data = graphDatasetDataAllRealized;
            graphDataValues.label = this.$t("ReportOverview.allRealized");
            graphDataValues.backgroundColor = "#26a69a";
            graphData.datasets.push(graphDataValues);
            graphDataValues = {};
            graphDataValues.data = graphDatasetDataAllCanceledRealized;
            graphDataValues.label = this.$t(
              "ReportOverview.allCanceledRealized"
            );
            graphDataValues.backgroundColor = "#f87979";
            graphData.datasets.push(graphDataValues);
            graphDataValues = {};
            graphDataValues.data = graphDatasetDataAllCanceledUnrealized;
            graphDataValues.label = this.$t(
              "ReportOverview.allCanceledUnrealized"
            );
            graphDataValues.backgroundColor = "#ba68c8";
            graphData.datasets.push(graphDataValues);
            this.chartData = Object.assign({}, graphData);
            //console.log("chartData:", this.chartData);

            //SUCTY ZA ZDROJE OBJEDNAVKY
            //IBA AKTUALNY MESIAC
            //aktualna Item - mesiac
            var monthItem = Object.values(tempResponseData)[0];
            //console.log("tempSource:", tempSource);
            //console.log("monthItem:", monthItem);

            //Uprava dat pre graf
            graphData = {};
            graphDataValues = {};
            graphLabels = [];
            graphDatasetDataAllRealized = [];
            var graphDatasetBackgroundColorAllRealized = [];
            var graphBackgroundColor = "#999999";

            //Loop cez jednotlive typy SOURCE
            Object.keys(monthItem).forEach((key) => {
              //vynulovat hodnoty
              tempSource = Object.assign({}, tempSourceDefault);
              allRealizedCounter = 0;
              allCanceledRealizedCounter = 0;
              allCanceledUnrealizedCounter = 0;
              var sourceItem = monthItem[key];
              tempSource.sourceName = key;
              //console.log(key, monthItem[key]);
              //LOOP cez jednotlivé stavy objednavok - STATUS
              Object.keys(sourceItem).forEach((key) => {
                //console.log(key, sourceItem[key]);
                if (key == "SETTLED") {
                  allRealizedCounter = allRealizedCounter + sourceItem[key];
                } else if (
                  key == "CANCELLED_ACCEPTED" ||
                  key == "CANCELLED_CHARGED" ||
                  key == "CANCELLED_FINISHED" ||
                  key == "CANCELLED_IN_PROGRESS" ||
                  key == "CANCELLED_SETTLED" ||
                  key == "CANCELLED_WAITING"
                ) {
                  allCanceledRealizedCounter =
                    allCanceledRealizedCounter + sourceItem[key];
                } else if (
                  key == "CANCELLED_NEW" ||
                  key == "CANCELLED_PENDING" ||
                  key == "CANCELLED_PLANNED" ||
                  key == "CANCELLED_PREREGISTERED"
                ) {
                  allCanceledUnrealizedCounter =
                    allCanceledUnrealizedCounter + sourceItem[key];
                }
              });
              tempSource.allRealized = allRealizedCounter;
              tempSource.allCanceledRealized = allCanceledRealizedCounter;
              tempSource.allCanceledUnrealized = allCanceledUnrealizedCounter;
              tempSourceSumary.push(tempSource);
              //console.log("tempSourceSumary", tempSourceSumary);

              //Pie graf
              graphLabels.push(key);
              if (key == "DISPATCH_TAXISERVICE") {
                graphBackgroundColor = "#01579B";
              } else if (key == "DRIVER_ANDROID") {
                graphBackgroundColor = "#004D40";
              } else if (key == "CUSTOMER_ANDROID") {
                graphBackgroundColor = "#B71C1C";
              } else if (key == "CUSTOMER_IOS") {
                graphBackgroundColor = "#B71C1C";
              } else if (key == "BRAND_ANDROID") {
                graphBackgroundColor = "#C62828";
              } else if (key == "BRAND_IOS") {
                graphBackgroundColor = "#C62828";
              } else if (key == "PBX") {
                graphBackgroundColor = "#7B1FA2";
              }
              graphDatasetBackgroundColorAllRealized.push(graphBackgroundColor);
              graphDatasetDataAllRealized.push(allRealizedCounter);
            });

            this.itemsSource = tempSourceSumary;
            //console.log("this.itemsSource", this.itemsSource);
            //PIE graph
            graphData.labels = graphLabels;
            //datasets: [{ data: [] }],
            graphData.datasets = [];
            graphDataValues = {};
            graphDataValues.data = graphDatasetDataAllRealized;
            graphDataValues.backgroundColor =
              graphDatasetBackgroundColorAllRealized;
            graphData.datasets.push(graphDataValues);
            this.chartPieData = Object.assign({}, graphData);
            //console.log("chartPieData:", this.chartPieData);
          }
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    sortObjectAsc(obj) {
      return Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    sortObjectDesc(obj) {
      return Object.keys(obj)
        .sort()
        .reverse()
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
  },
};
</script>
